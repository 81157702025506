import { isMobile } from 'mobile-device-detect';

const KlixiPlugin = {
  install(Vue, options) {
    let app = options.apps[0];
    Vue.prototype.$klixi = {
      ...options,
    };

    Vue.prototype.$o = {
      ...options,
      ...app,
    };
    Vue.prototype.$mobile = isMobile;
    if (isMobile) {
      document.body.classList.add('--mobile');
      document.body.classList.remove('--desktop');
    } else {
      document.body.classList.remove('--mobile');
      document.body.classList.add('--desktop');
    }
    Vue.prototype.$s = function (key) {
      if (!key || typeof key != 'string') return key;
      const s = options.strings;
      const k = key.toLowerCase();
      if (s && s[k]) return s[k];
      return key;
    };

    window.addEventListener('resize', () => {
      let vh = window.innerHeight;
      window._viewport = vh;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },
};

export default KlixiPlugin;
