<template>
  <div class="k__grid__item" :disabled="!item.isActive">
    <a
      href="#"
      @click.prevent="triggerItem()"
      class="k__shortcut"
      :class="{ '--mini': mini, '--disabled': !item.isActive }"
    >
      <bdc-icon :icon="item.id" />
      <span class="k__shortcut__label">{{ item.label }}</span>
      <span class="k__shortcut__desc" v-if="item.desc">{{ item.desc }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    mini: { type: Boolean },
  },
  methods: {
    triggerItem() {
      if (!this.item.isOngoing) {
        this.eventHub.$emit("showAlert", this.item.notOngoingMessage);
        return;
      }

      if (this.item.url === "#") {
        return;
      }

      if (this.item.isExternal) {
        window.open(this.item.url, "_blank");
        return;
      }

      document.location = this.item.url;
    },
  },
};
</script>
