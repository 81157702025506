var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "bdc__password-change k__forms",
      attrs: { method: "post" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updatePassword.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "bdc__password-change__form k__forms__content" },
        [
          _c("div", {
            staticClass: "k__alert --info",
            domProps: {
              innerHTML: _vm._s(_vm.server.i18n["web__new-password__help"]),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "k__form bdc__password-change__form__password" },
            [
              _c(
                "label",
                { staticClass: "k__form__label", attrs: { for: "password" } },
                [
                  _vm._v(
                    _vm._s(_vm.server.i18n["web__new-password__new-password"])
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password1,
                    expression: "password1",
                  },
                ],
                staticClass: "k__form__input",
                attrs: {
                  type: "password",
                  id: "password",
                  name: "password",
                  required: "",
                },
                domProps: { value: _vm.password1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password1 = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.passwordNote !== null
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bdc__password-change__form__password__strength",
                      class: "--note-" + _vm.passwordNote,
                    },
                    [
                      _c("div", {
                        staticClass:
                          "bdc__password-change__form__password__strength__note",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "bdc__password-change__form__password__strength__explanation",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.passwordNoteMessage) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formErrors.password1
                ? _c("p", { staticClass: "k__alert --danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formErrors.password1) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bdc__password-change__form__repeat k__form" },
            [
              _c(
                "label",
                {
                  staticClass: "k__form__label",
                  attrs: { for: "passwordBis" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.server.i18n["web__new-password__confirm-password"]
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password2,
                    expression: "password2",
                  },
                ],
                staticClass: "k__form__input",
                attrs: {
                  type: "password",
                  id: "passwordBis",
                  name: "passwordBis",
                  required: "",
                },
                domProps: { value: _vm.password2 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password2 = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.formErrors.password2
                ? _c("p", { staticClass: "k__alert --danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formErrors.password2) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "k__bton --full", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.server.i18n["web__new-password__button"]) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }