<template>
  <div class="bdc-file-input" :class="{'bdc-file-input--error': invalid}">
    <input type="file" :id="id" name="media" :accept="accept" :required="required" @change="validate" />
    <div v-if="maxSizeM"><small>{{ maxSizeM }}M max.</small></div>
  </div>
</template>

<script>
export default {
    props: {
        id: {type: String, required: true},
        accept: {type: String, required: true},
        required: {type: Boolean, default: false},
        maxSizeM: {type: Number, default: null}
    },
    mounted() {
        this._input = document.getElementById(this.id);
    },
    beforeDestroy() {
        this._input = null;
    },
    data() {
        return {
            _input: null,
            invalid: false
        }
    },
    methods: {
        validate(event) {
            this._input.setCustomValidity("");
            this.invalid = false;

            if (!this.maxSizeM) {
                return true;
            }

            const files = event.target.files;

            if (files.length === 0) {
                return true;
            }

            const fileSizeBytes = files[0].size;
            const M = 1024 * 1024;

            if (fileSizeBytes >= (this.maxSizeM * M)) {
                // const fileSizeM = Math.round(((fileSizeBytes / M) + Number.EPSILON) * 10 / 10);
                this._input.setCustomValidity("Fichier trop volumineux");
                this.invalid = true;
            }
        }
    }
}
</script>

<style lang="scss">
.bdc-file-input {
  &--error {
    color: #9a1c1c;
  }
}
</style>

