var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "k__subnav" },
    _vm._l(_vm.subnav, function (item) {
      return _c("li", { staticClass: "k__subnav__item" }, [
        _c(
          "a",
          {
            staticClass: "k__subnav__link",
            class: { "--active": _vm.isActive(item) },
            attrs: { href: item.url },
          },
          [_vm._v("\n            " + _vm._s(item.label) + "\n        ")]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }