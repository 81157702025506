<template>
    <div class="klixi-tools lorem-ipsum-default-class"></div>
</template>

<script>
export default {
    props: {
        type: {type: String},
        baseUrlApi: {type: String},
        baseUrlDownload: {type: String},
        account: {type: String},
        excludepages: {type: Array, default: () => []},
        excludemetrics: {type: Array, default: () => []},
        currentstart: {type: String},
        currentend: {type: String},
        previousstart: {type: String},
        previousend: {type: String},
        months: {type: Array, default: () => []},
        startmonth: {type: String},
        endmonth: {type: String},
        duration: {type: String},
        platforms: {type: Array, default:() => []},
        members: {type: Number, default: null}
    },
    mounted() {
        if (typeof KlixiTools === 'undefined') {
            return;
        }
        new KlixiTools('klixi-tools', {
            account: this.account,
            download: this.baseUrlDownload,
            apps: [
                {
                    name: this.type,
                    api: this.baseUrlApi,
                    token: 'token',
                    prev: {start: this.previousstart, end: this.previousend},
                    current: {start: this.currentstart, end: this.currentend}
                }
            ],
            duration: this.duration,
            platforms: this.platforms,
            months: this.months,
            startmonth: this.startmonth,
            endmonth: this.endmonth,
            excludePages: this.excludepages,
            excludeMetrics: this.excludemetrics,
            options: {color: 'red'},
            members: this.members,
            strings: {
                compare_to: this.server.i18n['web__ads__compare-to'],
                selectall: this.server.i18n['web__ads__selectall'],
                impressions: this.server.i18n['web__ads__print'],
                clicks: this.server.i18n['web__ads__clicks'],
                conversions: this.server.i18n['web__ads__conversions'],
                ctr: this.server.i18n['web__ads__ctr'],
                cost: this.server.i18n['web__ads__cost'],
                averagecpc: this.server.i18n['web__ads__cpc'],
                title_global: this.server.i18n['web__ads__global-result-title'],
                title_adwords: this.server.i18n['web__ads__global-result-title'],
                title_waze: this.server.i18n['web__ads__global-result-title'],
                title_fbads: this.server.i18n['web__ads__global-result-title'],
                title_facebook: this.server.i18n['web__ads__global-result-title'],
                title_gmb: this.server.i18n['web__ads__global-result-title'],
                title_instagram: this.server.i18n['web__ads__global-result-title'],
                campaign: this.server.i18n['web__ads__campaign'],
                title_campaign: this.server.i18n['web__ads__campaign-title'],
                title_adsgroup: this.server.i18n['web__ads__adsgroup-title'],
                title_ads: this.server.i18n['web__ads__ads-title'],
                title_keywords: this.server.i18n['web__ads__keywords-title'],
                table_title_campaign: this.server.i18n['web__ads__campaign-table-title'],
                adGroup: this.server.i18n['web__ads__adgroup'],
                keyword: this.server.i18n['web__ads__keyword'],
                conversionsRate: this.server.i18n['web__ads__conversion-rate'],
                this_week: this.server.i18n['web__ads__picker-this-week'],
                last_week: this.server.i18n['web__ads__picker-last-week'],
                last_7_days: this.server.i18n['web__ads__picker-last-7-days'],
                last_30_days: this.server.i18n['web__ads__picker-last-30-days'],
                this_month: this.server.i18n['web__ads__picker-this-month'],
                last_month: this.server.i18n['web__ads__picker-last-month'],
                this_year: this.server.i18n['web__ads__picker-this-year'],
                last_year: this.server.i18n['web__ads__picker-last-year'],
                at: 'au',
                table_title_ads: this.server.i18n['web__ads__ads-title'],
                table_title_keywords: this.server.i18n['web__ads__keywords-title'],
                table_title_adsgroup: this.server.i18n['web__ads__adsgroup-title'],
                campaignname: this.server.i18n['web__ads__campaign'],
                only: this.server.i18n['web__ads__only_btn'],
                week: this.server.i18n['web__ads__global-week-selector'],
                month: this.server.i18n['web__ads__global-month-selector'],
                year: this.server.i18n['web__ads__global-year-selector'],
                day: this.server.i18n['web__ads__global-day-selector'],
                help_impressions: this.server.i18n['web__ads__help-impressions'],
                help_clicks: this.server.i18n['web__ads__help-clicks'],
                help_conversions: this.server.i18n['web__ads__help-conversions'],
                help_ctr: this.server.i18n['web__ads__help-ctr'],
                help_cost: this.server.i18n['web__ads__help-cost'],
                help_averagecpc: this.server.i18n['web__ads__help-cpc'],
                title_localisation: this.server.i18n['web__ads__title_localisation'],
                title_calls: this.server.i18n['web__ads__title_calls'],
                map_title_impressions: this.server.i18n['web__ads__map_title_impressions'],
                type: this.server.i18n['web__ads__types'],
                starttime: this.server.i18n['web__ads__calls_starttime'],
                endtime: this.server.i18n['web__ads__calls_endtime'],
                status: this.server.i18n['web__ads__status'],
                duration: this.server.i18n['web__ads__calls_duration'],
                callercountry: this.server.i18n['web__ads__callercountry'],
                callerlocation: this.server.i18n['web__ads__callerlocation'],
                calltime: this.server.i18n['web__ads__calls_calltime'],
                adgroup: this.server.i18n['web__ads__adgroup'],
                country: this.server.i18n['web__ads__map_country'],
                region: this.server.i18n['web__ads__map_region'],
                city: this.server.i18n['web__ads__map_city'],
                regionid: this.server.i18n['web__ads__map_region'],
                countryid: this.server.i18n['web__ads__map_country'],
                cityid: this.server.i18n['web__ads__map_city'],
                nodata: this.server.i18n['web__ads__nodata'],
                callerarea: this.server.i18n['web__ads__calls_callerarea'],
                calltype: this.server.i18n['web__ads__calls_calltype'],
                map_title_clicks: this.server.i18n['web__ads__clicks'],
                map_title_target_area: this.server.i18n['web__ads__targets_title'],
                no_localisation_target: this.server.i18n['web__ads__no__targets'],
                searchshare: this.server.i18n['web__ads__searchshare'],
                searchlost: this.server.i18n['web__ads__searchlost'],
                help_searchshare: this.server.i18n['web__ads__help_searchshare'],
                help_searchlost: this.server.i18n['web__ads__help_searchlost'],
                table_total_campaign: this.server.i18n['web__ads__total_campaigns'],
                table_total_adsgroup: this.server.i18n['web__ads__total_adgroups'],
                table_total_ads: this.server.i18n['web__ads__total_ads'],
                table_total_keywords: this.server.i18n['web__ads__total_keywords'],
                filter: this.server.i18n['web__ads__apply_filters'],
                campaigns: this.server.i18n['web__ads__campaign'],
                types: this.server.i18n['web__ads__types'],
                adgroups: this.server.i18n['web__ads__adgroup'],
                table_title_facebook_ads: this.server.i18n['ads__facebook-title'],
                table_total_facebook_ads: this.server.i18n['ads__facebook-total-title'],
                reach: this.server.i18n['web__ads__fb_reach'],
                unique_clicks: this.server.i18n['web__ads__fb_unique_clicks'],
                actions: this.server.i18n['web__ads__fb_actions'],
                frequency: this.server.i18n['web__ads__fb_frequency'],
                targeting_title: this.server.i18n['web__ads__fb_targeting_title'],
                targeting_interests: this.server.i18n['web__ads__fb_targeting_interests'],
                targeting_countries: this.server.i18n['web__ads__fb_targeting_countries'],
                targeting_regions: this.server.i18n['web__ads__fb_targeting_regions'],
                targeting_cities: this.server.i18n['web__ads__fb_targeting_cities'],
                targeting_zips: this.server.i18n['web__ads__fb_targeting_zips'],
                canals : this.server.i18n['web__ads__fb_canals'],
                table_title_waze_ads: this.server.i18n['web__ads__waze-title'],
                table_total_waze_ads: this.server.i18n['web__ads__waze-total'],
                impressions_pins: this.server.i18n['web__ads__waze_pins'],
                impressions_search: this.server.i18n['web__ads__waze_search'],
                impressions_takeover: this.server.i18n['web__ads__waze_takeover'],
                engagement: this.server.i18n['web__ads__waze_engagement'],
                navigations: this.server.i18n['web__ads__waze_navigations'],
                targeting_waze_locations: this.server.i18n['web__ads__targeting_waze_locations'],

                help_reach: this.server.i18n['web__ads__help-reach'],
                help_unique_clicks: this.server.i18n['web__ads__help-unique-clicks'],
                help_actions: this.server.i18n['web__ads__help-actions'],
                help_frequency: this.server.i18n['web__ads__help-frequency'],
                help_impressions_pins: this.server.i18n['web__ads__help-impressions-pins'],
                help_impressions_takeover: this.server.i18n['web__ads__help-impressions-takeover'],
                help_impressions_search: this.server.i18n['web__ads__help-impressions-search'],
                help_engagement: this.server.i18n['web__ads__help-engagement'],
                help_navigations: this.server.i18n['web__ads__help-navigations'],
                help_queries: this.server.i18n['web__ads__help-queries'],
                help_views_search: this.server.i18n['web__ads__help-views-search'],
                help_view_maps: this.server.i18n['web__ads__help-views-maps'],
                help_website: this.server.i18n['web__ads__help-website'],
                help_directions: this.server.i18n['web__ads__help-directions'],
                help_pageviews: this.server.i18n['web__ads__help-pageviews'],
                help_fans: this.server.i18n['web__ads__help-fans'],
                help_newfans: this.server.i18n['web__ads__help-newfans'],
                help_media: this.server.i18n['web__ads__help-media'],
                help_followers: this.server.i18n['web__ads__help-followers'],
                help_following: this.server.i18n['web__ads__help-following'],
                help_phone: this.server.i18n['web__ads__help-phone'],
                help_rating: this.server.i18n['web__ads__help-rating'],
                help_reviews: this.server.i18n['web__ads__help-reviews'],

                queries: this.server.i18n['web__ads__queries'],
                views_search: this.server.i18n['web__ads__views-search'],
                view_maps: this.server.i18n['web__ads__views-maps'],
                website: this.server.i18n['web__ads__website'],
                directions: this.server.i18n['web__ads__directions'],
                pageviews: this.server.i18n['web__ads__pageviews'],
                fans: this.server.i18n['web__ads__fans'],
                newfans: this.server.i18n['web__ads__newfans'],
                media: this.server.i18n['web__ads__media'],
                followers: this.server.i18n['web__ads__followers'],
                following: this.server.i18n['web__ads__following'],
                phone: this.server.i18n['web__ads__phone'],
                rating: this.server.i18n['web__ads__rating'],
                reviews: this.server.i18n['web__ads__reviews'],
                members: this.server.i18n['web__ads__members'],
                social_reach: this.server.i18n['web__ads__social_reach'],
                help_social_reach: this.server.i18n['web__ads__help_social_reach'],
                title_clicks_devices: this.server.i18n['web__ads__title_clicks_devices'],
                title_conversion_devices: this.server.i18n['web__ads__title_conversion_devices'],
                title_cost_devices: this.server.i18n['web__ads__title_cost_devices']
            }
        });
    }
}
</script>
