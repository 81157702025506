<template>
    <ul class="k__subnav">
        <li class="k__subnav__item"
            v-for="item in subnav">
            <a class="k__subnav__link"
               :class="{'--active': isActive(item)}"
               :href="item.url">
                {{ item.label }}
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            active: {type: String},
            subnav: {type: Array}
        },
        methods: {
            isActive(item) {
                return item.id === this.active;
            }
        }
    }
</script>
