<template>
    <form class="bdc__password-change k__forms"
          method="post"
          ref="form"
          @submit.prevent="updatePassword">
        <div class="bdc__password-change__form k__forms__content">
            <div class="k__alert --info" v-html="server.i18n['web__new-password__help']"></div>

            <div class="k__form bdc__password-change__form__password">
                <label class="k__form__label"
                       for="password">{{ server.i18n['web__new-password__new-password'] }}</label>
                <input class="k__form__input"
                       type="password"
                       id="password"
                       name="password"
                       v-model="password1"
                       required>
                <div class="bdc__password-change__form__password__strength" :class="`--note-${passwordNote}`"
                     v-if="passwordNote !== null">
                    <div class="bdc__password-change__form__password__strength__note"></div>
                </div>
                <p class="bdc__password-change__form__password__strength__explanation">
                    {{ passwordNoteMessage }}
                </p>
                <p class="k__alert --danger"
                    v-if="formErrors.password1">
                    {{ formErrors.password1 }}
                </p>
            </div>
            <div class="bdc__password-change__form__repeat k__form">
                <label class="k__form__label"
                       for="passwordBis">{{ server.i18n['web__new-password__confirm-password'] }}</label>
                <input class="k__form__input"
                       type="password"
                       id="passwordBis"
                       name="passwordBis"
                       v-model="password2"
                       required>
                <p class="k__alert --danger"
                   v-if="formErrors.password2">
                    {{ formErrors.password2 }}
                </p>
            </div>
            <button class="k__bton --full"
                    type="submit">
                {{ server.i18n['web__new-password__button'] }}
            </button>
        </div>
    </form>
</template>

<script>
    import * as zxcvbn from 'zxcvbn';

    export default {
        computed: {
            passwordNote() {
                if (this.password1 || this.password2) {
                    return zxcvbn(this.password1).score;
                }

                return null;
            },
            passwordNoteMessage() {
                if (this.passwordNote === null) {
                    return null;
                }

                if (this.passwordNote <= 1) {
                    return this.server.i18n['web__new-password__note-weak'];
                }

                if (this.passwordNote <= 2) {
                    return this.server.i18n['web__new-password__note-average'];
                }

                if (this.passwordNote <= 3) {
                    return this.server.i18n['web__new-password__note-good'];
                }

                if (this.passwordNote > 3) {
                    return this.server.i18n['web__new-password__note-strong'];
                }

                return null;
            },
            errorMessage() {
                if (this.formErrors) {
                    if (this.formErrors['password1']) {
                        return this.formErrors['password1'];
                    }

                    if (this.formErrors['password2']) {
                        return this.formErrors['password2'];
                    }
                }

                return null;
            }
        },
        data() {
            return {
                value: null,
                options: ['list', 'of', 'options'],
                formErrors: {},
                password1: null,
                password2: null
            }
        },
        methods: {
            updatePassword() {
                this._clearErrors();

                if (this._validateForce() && this._validateMatch()) {
                    this.submit();
                }
            },
            submit() {
                this.$refs.form.submit();
            },
            _clearErrors() {
                this.$set(this.formErrors, 'password1', null);
                this.$set(this.formErrors, 'password2', null);
            },
            _validateForce() {
                if (!this.password1 || this.passwordNote < 3) {
                    this.$set(this.formErrors, 'password1', this.server.i18n['web__new-password__error-weak']);
                    return false;
                }

                return true;
            },
            _validateMatch() {
                if (this.password1 !== this.password2) {
                    this.$set(this.formErrors, 'password2', this.server.i18n['web__new-password__error-mismatch']);
                    return false;
                }

                return true;
            }
        }
    }
</script>

<style lang="scss">
    .bdc__password-change {
        &__form {

            &__error {
                color: red;
            }

            &__password {
                &__strength {
                    margin: .5rem 0 0 0;
                    height: .25rem;
                    border-radius: .5rem;
                    background: #eef3f6;
                    &.--note-0 {
                        .bdc__password-change__form__password__strength__note {
                            background-color: #F16344;
                            transform: scaleX(0.05);
                        }
                        .bdc__password-change__form__password__strength__explanation {
                            color: #F16344;
                        }
                    }
                    &.--note-1 {
                        .bdc__password-change__form__password__strength__note {
                            background-color: #9D8F98;
                            transform: scaleX(0.25);
                        }
                        .bdc__password-change__form__password__strength__explanation {
                            color: #9D8F98;
                        }
                    }
                    &.--note-2 {
                        .bdc__password-change__form__password__strength__note {
                            background-color: #44C5E4;
                            transform: scaleX(0.5);
                        }
                        .bdc__password-change__form__password__strength__explanation {
                            color: #44C5E4;
                        }
                    }
                    &.--note-3 {
                        .bdc__password-change__form__password__strength__note {
                            background-color: #44D7C3;
                            transform: scaleX(0.75);
                        }
                        .bdc__password-change__form__password__strength__explanation {
                            color: #44D7C3;
                        }
                    }
                    &.--note-4 {
                        .bdc__password-change__form__password__strength__note {
                            background-color: #44F195;
                            transform: scaleX(1);
                        }
                        .bdc__password-change__form__password__strength__explanation {
                            color: #44F195;
                        }
                    }

                    &__note {
                        margin: .75rem 0 .25rem 0;
                        height: 100%;
                        border-radius: .5rem;
                        transform-origin: left center;
                        transition: transform 0.5s ease, background 0.5s ease;
                    }

                    &__explanation {
                        margin: 0 0 1rem 0;
                        font-size: .75rem;
                        font-weight: 500;
                        text-align: right;
                    }
                }
            }
        }
    }
</style>
