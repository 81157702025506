var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contracts" },
    [
      _vm.hasLoaded
        ? _c("div", [
            _vm.stateFilters.length > 1
              ? _c(
                  "div",
                  { staticClass: "contracts__filters k__labels" },
                  _vm._l(_vm.stateFilters, function (filter) {
                    return _c(
                      "span",
                      {
                        key: filter.code + filter.count,
                        staticClass:
                          "invoice__filter k__label --actif --status",
                        class: _vm.getStateClasses(filter.code),
                        on: {
                          click: function ($event) {
                            return _vm.setFilter(filter)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(filter.label) +
                            " (" +
                            _vm._s(filter.count) +
                            ")\n      "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.displayed.length > 0
              ? _c("div", { staticClass: "k__table__wrapper" }, [
                  _c("table", { staticClass: "k__table" }, [
                    _c("thead", { staticClass: "k__table__header" }, [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__number"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__subject"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__start"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__end"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__state"]
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { staticClass: "k__table__body" },
                      _vm._l(_vm.displayed, function (contract) {
                        return _c(
                          "tr",
                          { key: contract.name, staticClass: "k__table__line" },
                          [
                            _c("td", { staticClass: "k__table__col" }, [
                              _vm._v(_vm._s(contract.name)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _c("b", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      contract.product.descriptionSale
                                        ? contract.product.descriptionSale
                                        : contract.product.name
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _vm._v(_vm._s(contract.dateStart)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _vm._v(_vm._s(contract.dateEnd)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "k__label --status",
                                  class: _vm.getStateClasses(contract.state),
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(contract.stateLabel) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _c(
                  "p",
                  { staticClass: "k__alert --info contracts__disclaimer" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.server.i18n["web__billings__no-contracts"]) +
                        "\n    "
                    ),
                  ]
                ),
          ])
        : _c("loading"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }