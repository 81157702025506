<template>
    <div class="klixi-tools lorem-ipsum-default-class"></div>
</template>

<script>
    export default {
        props: {
            edit: {type: String, default: null},
        },
        mounted() {

            if (typeof KlixiTools === 'undefined') {
                return;
            }

            new KlixiTools("klixi-tools", {
                debugg: false,
                access: "",
                apps: [
                    {
                        name: "social",
                        api: '/api',
                        fixtures: false,
                        color: "#47cff4",
                        proxy: "/api/proxy",
                        exclude: ['calendrier', 'statistiques'],
                    }
                ],
                isRegicom: true,
                edit: this.edit,
                multiImages: true,
                settings: true,
                options: {
                },
                strings: {
                    add: this.server.i18n['web__socials__add-post'],
                    previous: this.server.i18n['web__socials__previous'],
                    next: this.server.i18n['web__socials__next'],
                    publication: this.server.i18n['web__socials__publication'],
                    publications: this.server.i18n['web__socials__publications'],
                    now: this.server.i18n['web__socials__now'],
                    plan: this.server.i18n['web__socials__scheduled'],
                    add_publication: this.server.i18n['web__socials__add_post'],
                    edit_publication: this.server.i18n['web__socials__edit_post'],
                    upload_img_message: this.server.i18n['web__socials__load_media'],
                    status_draft: this.server.i18n['web__socials__status_draft'],
                    status_scheduled: this.server.i18n['web__socials__status_scheduled'],
                    status_published: this.server.i18n['web__socials__status_published'],
                    title_publications: this.server.i18n['web__socials__title_publications'],
                    title_monitoring: this.server.i18n['web__socials__title_monitoring'],
                    title_statistiques: this.server.i18n['web__socials__title_stats'],
                    close:this.server.i18n['web__socials__close_btn'],
                    save: this.server.i18n['web__socials__save_btn'],
                    cancel: this.server.i18n['web__socials__cancel_btn'],
                    delete: this.server.i18n['web__socials__remove_btn'],
                    publish: this.server.i18n['web__socials__publish_btn'],
                    no_publications: this.server.i18n['web__socials__no_publications'],
                    settings_title: this.server.i18n['web__socials__publications_settings_title'],
                    settings_message: this.server.i18n['web__socials__publications_settings_message'],
                    settings_label: this.server.i18n['web__socials__publications_settings_label'],
                    delayed: this.server.i18n['web__socials__publications_settings_delayed'],
                    off: this.server.i18n['web__socials__publications_settings_off'],
                    title_publications_list:this.server.i18n['web__socials__title_publications_list'],
                    title_publications_calendar: this.server.i18n['web__socials__title_publications_calendar'],
                    title_publications_kanban: this.server.i18n['web__socials__title_publications_kanban'],
                    cta: this.server.i18n['web__socials__publications_cta'],
                    none: this.server.i18n['web__socials__publications_cta_none'],
                    sign_up: this.server.i18n['web__socials__publications_cta_signup'],
                    book: this.server.i18n['web__socials__publications_cta_book'],
                    shop: this.server.i18n['web__socials__publications_cta_shop'],
                    learn_more: this.server.i18n['web__socials__publications_cta_learnmore'],
                    settings: this.server.i18n['web__socials__publications_settings'],
                    duplicate: this.server.i18n['web__socials__duplicate_btn'],
                    url: this.server.i18n['web__socials__url'],
                    update: this.server.i18n['web__socials__update_btn'],
                    edit_now: this.server.i18n['web__socials__edit_now'],
		                add_column: this.server.i18n['web__socials_add_column'],
                    network: this.server.i18n['web__socials_monitoring_network'],
                    type: this.server.i18n['web__socials_monitoring_type'],
                    posts: this.server.i18n['web__socials_monitoring_posts'],
                    reviews: this.server.i18n['web__socials_monitoring_reviews'],
                    comment: this.server.i18n['web__socials_monitoring_comment'],
                    mentions: this.server.i18n['web__socials_monitoring_mentions'],
                    like: this.server.i18n['web__socials_monitoring_like'],
                    reply: this.server.i18n['web__socials_monitoring_reply'],
                    nodata: this.server.i18n['web__socials__monitoring_nodata'],
                    no_column: this.server.i18n['web__socials__monitoring_no_column'],
                    filter: this.server.i18n['web__socials__filter_btn'],
                    social_rss_title: this.server.i18n['web__social_rss_title'],
                    social_rss_info: this.server.i18n['web__social_rss_info'],
                    empty_post_error: this.server.i18n['web__social__empty_post_error'],
                    social_message_video_min: this.server.i18n['web__social_video_min_1_sec'],
                    social_message_video_max: this.server.i18n['web__social_video_max_1_min'],
                    social_message_video_support: this.server.i18n['web__social_no_video_support'],
                    publication_ok: this.server.i18n['web__social__publication_ok'],
                    publication_ko: this.server.i18n['web__social__publication_ko'],
                    social_message_insta_no_img: this.server.i18n['web__social_message_insta_no_img'],
                    networks: this.server.i18n['web__social__networks'],
                    social_message_static_gif: this.server.i18n['social_message_static_gif'],
                    post_error: this.server.i18n['web__social_post_error'],
                    social_message_multi_image_networks: this.server.i18n['web__social_message_multi_image_networks'],
                  title_configuration: this.server.i18n['web__social_title_configuration'],
                  title_publications_hashtags: this.server.i18n['web__social_title_publications_hashtags'],
                  edit_hashtags: this.server.i18n['web__social_edit_hashtags'],
                  add_hashtags: this.server.i18n['web__social_add_hashtags'],
                  add_hashtag: this.server.i18n['web__social_new_hashtag_btn'],
                  hashtag_list_title: this.server.i18n['web__social_hashtag_list_title'],
                  hashtag_list_tags: this.server.i18n['web__social_hashtag_list_tags'],
                  confirm_delete_hashtags: this.server.i18n['web__social__confirm_delete_hashtags'],
                  social_message_social_infos: this.server.i18n['web__social_message_social_infos'],
                },
            });
        }
    }
</script>
