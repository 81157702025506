<template>
    <i class="k__icon"
       v-if="spriteId">
        <svg xmlns="http://www.w3.org/2000/svg"
             class="inline-svg">
            <use :xlink:href="spriteId"/>
        </svg>
    </i>
</template>

<script>
    const MAP = {
        'dashboard': '0',
        'website': '1',
        'social': '2',
        'contact': '3',
        'email': '14',
        'stats': '5',
        'logout': '6',
        'services': '16',
        'adwords': '8',
        'powerads': '9',
        'keymoments': '10',
        'facebook': '11',
        'waze': '12',
        'sms': '13',
        'retargeting': '15',
        'download': '17',
        'socialreporting': '2',
        'networkstats': '19',
        'networksocialstats': '18',
        'networklist': '3',
        'poleposition': '22',
    };

    export default {
        props: {
            icon: {type: String, required: true}
        },
        computed: {
            spriteId() {
                if (MAP.hasOwnProperty(this.icon)) {
                    return '#svg-sprite-' + MAP[this.icon];
                }

                return null;
            }
        }
    }
</script>

