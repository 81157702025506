<template>
  <div class="contracts">
    <div v-if="hasLoaded">
      <div class="contracts__filters k__labels" v-if="stateFilters.length > 1">
        <span
          v-for="filter of stateFilters"
          :key="filter.code + filter.count"
          class="invoice__filter k__label --actif --status"
          :class="getStateClasses(filter.code)"
          @click="setFilter(filter)"
        >
          {{ filter.label }} ({{ filter.count }})
        </span>
      </div>
      <div v-if="displayed.length > 0" class="k__table__wrapper">
        <table class="k__table">
          <thead class="k__table__header">
            <tr>
              <th>{{ server.i18n['web__billings__header__number'] }}</th>
              <th>{{ server.i18n['web__billings__header__subject'] }}</th>
              <th>{{ server.i18n['web__billings__header__start'] }}</th>
              <th>{{ server.i18n['web__billings__header__end'] }}</th>
              <th>{{ server.i18n['web__billings__header__state'] }}</th>
            </tr>
          </thead>
          <tbody class="k__table__body">
            <tr
              class="k__table__line"
              v-for="contract in displayed"
              :key="contract.name"
            >
              <td class="k__table__col">{{ contract.name }}</td>
              <td class="k__table__col">
                <b>
                  {{
                    contract.product.descriptionSale
                      ? contract.product.descriptionSale
                      : contract.product.name
                  }}
                </b>
              </td>
              <td class="k__table__col">{{ contract.dateStart }}</td>
              <td class="k__table__col">{{ contract.dateEnd }}</td>
              <td class="k__table__col">
                <span
                  class="k__label --status"
                  :class="getStateClasses(contract.state)"
                >
                  {{ contract.stateLabel }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="k__alert --info contracts__disclaimer" v-else>
        {{ server.i18n['web__billings__no-contracts'] }}
      </p>
    </div>
    <loading v-else />
  </div>
</template>

<script>
import Loading from '../../klixi/klixi_app/src/components/Loading.vue'

export default {
  components: { Loading },
  computed: {
    stateFilters() {
      if (this.contracts.length <= 0) {
        return []
      }

      let stateFilters = {}

      this.contracts.forEach((contract) => {
        if (!stateFilters.hasOwnProperty(contract.state)) {
          stateFilters[contract.state] = {
            code: contract.state,
            label: contract.stateLabel,
            count: 1,
          }
        } else {
          stateFilters[contract.state].count++
        }
      })

      return Object.values(stateFilters)
    },
    displayed() {
      if (!this.currentFilter) {
        return this.contracts
      }

      return this.contracts.filter(
        (contract) => contract.state === this.currentFilter.code,
      )
    },
  },
  data() {
    return {
      hasLoaded: false,
      currentFilter: null,
      contracts: [],
    }
  },
  created() {
    this.request({ url: this.server.url['api_contract'] })
      .then((contracts) => (this.contracts = this._makeContracts(contracts)))
      .finally(() => (this.hasLoaded = true))
  },
  methods: {
    _makeContracts(contracts) {
      let flatten = []

      contracts.forEach((contract) =>
        contract.contractLines.forEach((line) =>
          flatten.push(this._makeContract(contract, line)),
        ),
      )

      return flatten
    },
    _makeContract(contractBase, contractLine) {
      return {
        name: contractBase.name,
        dateStart: this.formatOdooDate(contractLine.dateStart),
        dateEnd: this.formatOdooDate(contractLine.dateEnd),
        state: contractLine.state,
        stateLabel: this.formatOdooState(contractLine.state),
        product: contractLine.product,
      }
    },
    getStateClasses(stateCode) {
      let classes = ['in-progress', 'upcoming'].includes(stateCode)
        ? '--warning'
        : '--release'

      if (this.currentFilter && stateCode === this.currentFilter.code) {
        classes += ' --active'
      }

      return classes
    },
    setFilter(filter) {
      if (this.currentFilter && this.currentFilter.code === filter.code) {
        this.currentFilter = null

        return
      }

      this.currentFilter = filter
    },
  },
}
</script>
