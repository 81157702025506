<template>
  <div class="k__loading">
    <div class="k__loading__content" v-if="isOk">
      <svg
        class="k__loading__svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="25 25 50 50"
        style="transform: rotate(0deg);"
      >
        <circle
          fill="transparent"
          cx="50"
          cy="50"
          r="20"
          stroke-width="5"
          stroke-dasharray="125.664"
          stroke-dashoffset="125.66370614359172px"
          class="k__loading__svg__path"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ["color"],
  data() {
    return {
      isOk: true
    };
  },
  // mounted() {
  //   let s = this;
  //   setTimeout(() => {
  //     s.isOk = true;
  //   }, 100);
  // },
  methods: {
    // isOk(){
    // }
  }
};
</script>

<style>
.k__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1.5em;
  width: 1.5em;
  z-index: 9999;
  transform: translate(-50%, -50%);
}
.k__loading__content {
  transform: translate(-50%, -50%);
}
.k__loading__svg {
  animation: progress-circular-rotate 1.4s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k__loading__svg__path {
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0px;
  /* stroke: var(--color); */
  stroke: #47cff4;
}
@keyframes progress-circular-rotate {
  to {
    transform: rotate(1turn);
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  to {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
</style>
