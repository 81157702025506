var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bdc-file-input",
      class: { "bdc-file-input--error": _vm.invalid },
    },
    [
      _c("input", {
        attrs: {
          type: "file",
          id: _vm.id,
          name: "media",
          accept: _vm.accept,
          required: _vm.required,
        },
        on: { change: _vm.validate },
      }),
      _vm._v(" "),
      _vm.maxSizeM
        ? _c("div", [_c("small", [_vm._v(_vm._s(_vm.maxSizeM) + "M max.")])])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }