<template>
    <div>
        <bdc-item :mini="mini"
                  :item="item"
                  :key="randomId + '_' + item.id + '_' + item.desc"
                  v-for="item in orderedItemIds"/>
    </div>
</template>

<script>
    import BdcItem from './BdcItem.vue';

    export default {
        components: {BdcItem},
        props: {
            mini: {type: Boolean},
            items: {type: Array}
        },
        computed: {
            randomId() {
                return '_' + Math.random().toString(36).substr(2, 9);
            },
            orderedItemIds() {
                return this.items.filter(item => item.isActive)
                    .concat(this.items.filter(item => !item.isActive));
            }
        }
    }
</script>
