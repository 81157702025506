var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.spriteId
    ? _c("i", { staticClass: "k__icon" }, [
        _c(
          "svg",
          {
            staticClass: "inline-svg",
            attrs: { xmlns: "http://www.w3.org/2000/svg" },
          },
          [_c("use", { attrs: { "xlink:href": _vm.spriteId } })]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }