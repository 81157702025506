import Vue from "vue";
import Vuex from "vuex";

import dayjs from "dayjs";

Vue.use(Vuex);

// import { app } from "./app";

export default new Vuex.Store({
  state: {
    sizes: {
      maxContentHeight: 0,
    },
    // app: {
    //   name: null,
    //   page: null,
    //   def: null,
    //   pages: [],
    //   kpis: [],
    //   // store: {},
    // },
  },
  mutations: {
    SET_SIZES_MAX_CONTENT_HEIGHT(state, max) {
      state.sizes.maxContentHeight = max - 4;
    },
    SET_APP(state, obj) {
      // state.app.name = obj.name;
      // console.log("SET APP ", obj);
      // state.app.options = obj.options;
      // console.log("has module ?", this.hasModule("app"));
      if (!this.hasModule("app")) this.registerModule("app", obj.store);
      this.commit("app/INIT", obj);

      // if (obj.store) {
      //   console.log("init store app ", obj.store);
      //   this.registerModule("app/store", obj.store);
      // }
    },
    RESET_APP(state) {
      if (this.hasModule("app")) this.unregisterModule("app");
      delete state.app;
    },
  },
  actions: {},
  modules: {},
});
