import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr');
import KlixiTools from '@/apps/KlixiTools.vue';
import KlixiPlugin from '@/plugins/KlixiPlugin';
import KlixiFilters from '@/plugins/KlixiFilters';
// import "./registerServiceWorker";
// import Vue2Editor from 'vue2-editor';

// Vue.use(Vue2Editor);
import VueObserveVisibility from 'vue-observe-visibility';

import { generateColors } from '@/utils';

Vue.use(VueObserveVisibility);

import store from './store';

// import "vue-multiselect/dist/vue-multiselect.min.css";

Vue.config.productionTip = false;

function klixiMount(el, args) {
  Vue.use(KlixiPlugin, {
    ...el.dataset,
    ...args,
  });
  new Vue({
    store,
    render: (h) =>
      h(KlixiTools, {
        attrs: {
          class: el.classList,
        },
      }),
  }).$mount(el);
}

window.KlixiTools = function (target = 'klixi-tools', args) {
  const t = document.getElementsByClassName(target);
  if (!t) return;
  let nb = t.length;
  const params = { ...args };
  if (params && params.apps[0])
    params.colors = generateColors(params.apps[0].color);
  for (let i = 0; i < nb; i++) {
    klixiMount(t[i], params);
  }
};
