var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "bdc-extranet__form bdc-message-edit",
      attrs: { method: "post", enctype: "multipart/form-data" },
      on: {
        submit: function ($event) {
          _vm.loading = true
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.id,
            expression: "id",
          },
        ],
        attrs: { type: "hidden", name: "id" },
        domProps: { value: _vm.id },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.id = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bdc-extranet__form__widget" }, [
        _c("label", { attrs: { for: "name" } }, [_vm._v("Nom *")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          attrs: { type: "text", id: "name", name: "name", required: "" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", [_vm._v("Statut")]),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "state" } }, [_vm._v("Mode *")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.state,
                    expression: "state",
                  },
                ],
                attrs: { id: "state", name: "state", required: "" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.state = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "auto", selected: "" } }, [
                  _vm._v("Automatique (dates)"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "active" } }, [
                  _vm._v("Actif forcé"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "inactive" } }, [
                  _vm._v("Inactif forcé"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "start" } }, [_vm._v("Date début *")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.start,
                  expression: "start",
                },
              ],
              attrs: { type: "date", id: "start", name: "start", required: "" },
              domProps: { value: _vm.start },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.start = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "end" } }, [_vm._v("Date fin")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.end,
                  expression: "end",
                },
              ],
              attrs: { type: "date", id: "end", name: "end" },
              domProps: { value: _vm.end },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.end = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", [_vm._v("Identifiants clients")]),
        _vm._v(" "),
        _c("div", { staticClass: "bdc-extranet__form__widget" }, [
          _c("label", { attrs: { for: "clients" } }, [
            _vm._v("Liste d'identifiants séparés par des virgules"),
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.clients,
                expression: "clients",
              },
            ],
            attrs: { id: "clients", name: "clients" },
            domProps: { value: _vm.clients },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.clients = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bdc-extranet__form__widget" }, [
          _c("label", { attrs: { for: "clientsFile" } }, [
            _vm._v("Remplir avec la première colonne du fichier CSV"),
          ]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "file", id: "clientsFile", accept: ".csv" },
            on: { change: _vm.clientsCsvToClientsText },
          }),
        ]),
        _vm._v(" "),
        _vm.csvError
          ? _c("p", { staticClass: "k__alert --danger" }, [
              _vm._v(_vm._s(_vm.csvError)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v("Contenu")]),
          _vm._v(" "),
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "contentType" } }, [
              _vm._v("Couleur de fond (code hexadécimal) *"),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.color,
                    expression: "color",
                  },
                ],
                attrs: {
                  type: "text",
                  id: "color",
                  name: "color",
                  placeholder: "#0b8db0",
                  required: "",
                },
                domProps: { value: _vm.color },
                on: {
                  change: _vm.validateColor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.color = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "bdc-message-edit__colorpreview",
                style: { "background-color": _vm.color },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "contentType" } }, [_vm._v("Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.contentType,
                    expression: "contentType",
                  },
                ],
                attrs: { id: "contentType", name: "contentType" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.contentType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "html", selected: "" } }, [
                  _vm._v("HTML"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "image" } }, [_vm._v("Image")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "video" } }, [_vm._v("Vidéo")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.contentType === "html"
            ? [
                _c("div", { staticClass: "bdc-extranet__form__widget" }, [
                  _c("label", { attrs: { for: "head" } }, [_vm._v("Head")]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.head,
                        expression: "head",
                      },
                    ],
                    attrs: { id: "head", name: "head" },
                    domProps: { value: _vm.head },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.head = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bdc-extranet__form__widget" }, [
                  _c("label", { attrs: { for: "body" } }, [_vm._v("Body *")]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.body,
                        expression: "body",
                      },
                    ],
                    attrs: { id: "body", name: "body", required: "" },
                    domProps: { value: _vm.body },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.body = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.contentType === "image"
            ? _c(
                "div",
                { staticClass: "bdc-extranet__form__widget" },
                [
                  _c("label", { attrs: { for: "image" } }, [_vm._v("Image *")]),
                  _vm._v(" "),
                  _c("bdc-file-input", {
                    attrs: {
                      id: "image",
                      accept: ".jpg,.png,.jpeg,.gif",
                      required: !_vm.image,
                      "max-size-m": 5,
                    },
                  }),
                  _vm._v(" "),
                  _vm.image
                    ? _c(
                        "div",
                        { staticClass: "bdc-message-edit__mediapreview" },
                        [
                          _c("img", {
                            attrs: {
                              alt: "Preview",
                              src: _vm.image,
                              width: "250",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentType === "video"
            ? _c(
                "div",
                { staticClass: "bdc-extranet__form__widget" },
                [
                  _c("label", { attrs: { for: "video" } }, [_vm._v("Vidéo *")]),
                  _vm._v(" "),
                  _c("bdc-file-input", {
                    attrs: {
                      id: "video",
                      accept: ".mp4",
                      required: !_vm.video,
                      "max-size-m": 30,
                    },
                  }),
                  _vm._v(" "),
                  _vm.video
                    ? _c(
                        "div",
                        { staticClass: "bdc-message-edit__mediapreview" },
                        [
                          _c(
                            "video",
                            { attrs: { width: "250", controls: "" } },
                            [
                              _c("source", {
                                attrs: { src: _vm.video, type: "video/mp4" },
                              }),
                              _vm._v(
                                "\n          Oups ! Votre navigateur ne peut pas afficher notre vidéo...\n        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentType === "video" || _vm.contentType === "image"
            ? _c("div", { staticStyle: { display: "flex" } }, [
                _c("div", { staticClass: "bdc-extranet__form__widget" }, [
                  _c("label", { attrs: { for: "maxDisplays" } }, [
                    _vm._v("Largeur (px) *"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.width,
                        expression: "width",
                      },
                    ],
                    attrs: {
                      type: "number",
                      id: "width",
                      name: "width",
                      step: "1",
                      min: "0",
                      placeholder: "1440",
                      required: "",
                    },
                    domProps: { value: _vm.width },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.width = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bdc-extranet__form__widget" }, [
                  _c("label", { attrs: { for: "maxDisplays" } }, [
                    _vm._v("Hauteur (px) *"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.height,
                        expression: "height",
                      },
                    ],
                    attrs: {
                      type: "number",
                      id: "height",
                      name: "height",
                      step: "1",
                      min: "0",
                      placeholder: "900",
                      required: "",
                    },
                    domProps: { value: _vm.height },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.height = $event.target.value
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "url" } }, [
              _vm._v("URL bouton d'action"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.url,
                  expression: "url",
                },
              ],
              attrs: {
                type: "url",
                id: "url",
                name: "url",
                placeholder: "https://www.regicom.fr",
              },
              domProps: { value: _vm.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.url = $event.target.value
                },
              },
            }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", [_vm._v("Trigger")]),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticClass: "bdc-extranet__form__widget" }, [
            _c("label", { attrs: { for: "trigger" } }, [_vm._v("Mode")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.trigger,
                    expression: "trigger",
                  },
                ],
                attrs: { id: "trigger", name: "trigger" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.trigger = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("A chaque session"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "max_displays" } }, [
                  _vm._v(
                    "Un certain nombre de fois (mais 1 seule fois par session)"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "option",
                  { attrs: { value: "untill_clicked", disabled: !_vm.url } },
                  [_vm._v("Jusqu'à ce que le bouton d'action soit cliqué")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.trigger === "max_displays"
            ? _c("div", { staticClass: "bdc-extranet__form__widget" }, [
                _c("label", { attrs: { for: "maxDisplays" } }, [
                  _vm._v("Nombre de fois *"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.maxDisplays,
                      expression: "maxDisplays",
                    },
                  ],
                  attrs: {
                    type: "number",
                    id: "maxDisplays",
                    name: "maxDisplays",
                    step: "1",
                    min: "1",
                    required: "",
                  },
                  domProps: { value: _vm.maxDisplays },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.maxDisplays = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "text-align": "right", margin: "1rem 0" } }, [
        _c(
          "a",
          {
            staticClass: "k__bton --rounded --mini",
            attrs: { href: _vm.back },
          },
          [_vm._v("Annuler")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "k__bton --rounded",
            class: { "k__bton--blink": _vm.loading },
            attrs: { type: "submit" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.loading ? "Chargement en cours" : "Sauvegarder") +
                "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }