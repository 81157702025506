import Vue from 'vue';
import { latLng } from 'leaflet';
import { TinyColor } from '@ctrl/tinycolor';
import dayjs from 'dayjs';
import axios from 'axios';

import { nanoid } from 'nanoid';

import slugify from 'slugify';

import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

var dayOfYear = require('dayjs/plugin/dayOfYear');
dayjs.extend(dayOfYear);

export function generateColors(color) {
  if (!color) {
    const colors = new TinyColor('#ec6d8b').splitcomplement();
    return colors.map((t) => t.toHexString());
  }
  const colors = new TinyColor(color).splitcomplement();
  return colors.map((t) => t.toHexString());
}

export function getStorage() {
  let storage = localStorage.getItem('klixi-tools');
  return JSON.parse(storage);
}

export function setStorage(obj) {
  localStorage.setItem('klixi-tools', obj);
}

export function updateStorage(obj) {
  let storage = getStorage();
  if (!storage) {
    setStorage(obj);
    return;
  } else {
    setStorage({ ...storage, ...obj });
  }
  localStorage.setItem('klixi-tools', JSON.stringify(obj));
}

export function deleteStorage() {
  localStorage.removeItem('klixi-tools');
}

export function register_global_components(cpts) {
  for (var cpt of cpts) {
    Vue.component(cpt.name, cpt.import);
  }
}

export function wait(secondes) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, secondes * 1000);
  });
}

export function getDuration(start, end, unit = 'day') {
  return { value: dayjs(end).diff(dayjs(start), unit) + 1, unit: unit };
}

export function getDateFormat(range) {
  switch (range) {
    case 'month':
      return 'MMMM';
      break;
    case 'week':
      return 'DD/MM';
      break;
    case 'year':
      return 'YYYY';
  }
}
export function slugifyy(url) {
  if (!url) return;
  return slugify(url, {
    lower: true,
    strict: true,
    remove: /[*+~.()'"!:@]/g,
  });
}

export function urlify(args, hasGa = true, isFirst = true) {
  let url = '';
  for (let key in args) {
    if (typeof args[key] === 'object') {
      for (let row of args[key]) {
        url +=
          key != 'applis' && hasGa ? `&${key}[]=ga:${row}` : `&${key}[]=${row}`;
        // url += key != "applis" ? `&${key}[]=ga:${row}` : ``;
      }
    } else {
      url += `&${key}=${args[key]}`;
    }
  }
  return isFirst ? `?${url}` : url;
}

export function mergeSources(data, next) {
  if (!Object.keys(data).length) return next;
  let result = { ...data };
  let rows = next.data.reports[0].data.rows;
  result.data.reports[0].data.rows = result.data.reports[0].data.rows.concat(
    rows
  );
  return result;
}

export function addSourceDimension(src, dim) {
  let r = { ...src };
  r.data.reports[0].columnHeader.dimensions.push('ga:applis');
  for (let i in r.data.reports[0].data.rows) {
    let row = r.data.reports[0].data.rows[i];
    row.dimensions.push(dim);
  }
  return r;
}

export function getPrevRange(dates) {
  let d = {
    start: dayjs(dates.start).format('YYYY-MM-DD'),
    end: dayjs(dates.end).format('YYYY-MM-DD'),
  };
  let duration = getDuration(d.start, d.end);
  return {
    start: dayjs(d.start).add(-duration, 'day').format('DD/MM/YYYY'),
    end: dayjs(d.start).add(-1, 'day').format('DD/MM/YYYY'),
  };
}

export function convertmicros(value) {
  return value / 1000000;
}

export function convert(v, metric, noformat = false) {
  if (metric && metric.convert) {
    let f = eval(`convert${metric.convert}`);
    return formatNumber(round(f(v), metric.toFixe));
  } else if (!noformat) {
    return formatNumber(v);
  }
  return v;
}

export function formatNumber(v) {
  if (!v) return;
  if (v < 1000) return v;
  return v.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function round(value, toFixe = 0) {
  let v = (Math.round(value * 100) / 100).toFixed(toFixe);
  if (isNaN(v) || !v) {
    return 0;
  } else {
    return v;
  }
}

export function sample(obj) {
  if (!obj.all) return;
  let dates = {
    start: obj.dates ? dayjs(obj.dates.start).format('YYYY-MM-DD') : null,
    end: obj.dates ? dayjs(obj.dates.end).format('YYYY-MM-DD') : null,
  };
  let o = {
    resume: {
      current: {
        date: {
          start: dates.start,
          end: dates.end,
        },
      },
      prev: {
        date: {},
      },
    },
    data: {},
  };

  o.resume.current.duration = getDuration(
    o.resume.current.date.start,
    o.resume.current.date.end
  );

  o.resume.prev.date.end = dayjs(o.resume.current.date.start)
    .add(-1, 'day')
    .format('YYYY-MM-DD');

  o.resume.prev.date.start = dayjs(o.resume.prev.date.end)
    .add(-o.resume.current.duration + 1, 'day')
    .format('YYYY-MM-DD');

  o.resume.prev.duration = getDuration(
    o.resume.prev.date.start,
    o.resume.prev.date.end
  );

  let filteredCurrentDate = filterDates(obj.all, {
    start: o.resume.current.date.start,
    end: o.resume.current.date.end,
  });

  let filteredPrevDate = filterDates(obj.all, {
    start: o.resume.prev.date.start,
    end: o.resume.prev.date.end,
  });

  o.resume.metrics = objectify(obj.settings.metrics);
  o.resume.dimensions = objectify(obj.settings.dimensions);

  o.resume.current.metric = obj.metric || {};

  o.resume.current.dims = dimsNames(obj.all, filteredCurrentDate);

  o.resume.prev.dims = dimsNames(obj.all, filteredPrevDate);

  o.data.current = filterDims(obj.all, filteredCurrentDate, obj.dimensions);

  o.data.prev = filterDims(obj.all, filteredPrevDate, obj.dimensions);

  return o;
}

export function filterDates(all, date) {
  // console.log("filter dates ", all, date);
  let rows = all.data;

  let dateIndex = all.columnHeader.dimensions.findIndex(
    (dim) => dim === `date`
  );
  let result = rows.filter((r) =>
    isBetween(r.dimensions[dateIndex], date.start, date.end)
  );
  // console.log("result is ", result);
  return result;
}

function isBetween(date, start, end) {
  if (dayjs(date).diff(dayjs(start), 'days') < 0) return false;
  if (dayjs(date).diff(dayjs(end), 'days') > 0) return false;
  return true;
}
export function calcMetricObject({ data, metric, src, sample }) {
  if (typeof metric.operation === 'object') {
    // return {};
    return calcLocalMetric(data, metric);
  }
  let value = {};

  if (metric.view) {
    value.current = calcMetric(
      data[metric.view.src][sample].current,
      metric,
      getMetricIndex(data[metric.view.src].all, metric.ref)
    );
    value.prev = calcMetric(
      data[metric.view.src][sample].prev,
      metric,
      getMetricIndex(data[metric.view.src].all, metric.ref)
    );
  } else {
    value.current = calcMetric(
      data[src][sample].current,
      metric,
      getMetricIndex(data[src].all, metric.ref)
    );
    value.prev = calcMetric(
      data[src][sample].prev,
      metric,
      getMetricIndex(data[src].all, metric.ref)
    );
  }

  value = calcDiff(value.current, value.prev, metric);

  return value;
}

export function calcMetric(array, metric, ref, metrics) {
  if (!array.length) return;
  // if (typeof metric.operation === 'object') {
  // console.log("CALC METRIC ", calcLocalMetric(array, metric, true, true));
  // console.log(metric.name, metric.operation, array);
  // return calcLocalMetric(array, metric, true, true, metrics);
  // }
  let f;
  // console.log("will calc ", array, metric.index, ref, metrics);
  try {
    f = eval(metric.operation);
  } catch (e) {}
  if (typeof f != 'function') return 'methode inexistante';

  let result = round(f(array, metric.id, ref), metric.toFixe);

  // if (metric.name === 'objective') {
  //   console.log('calc ', array, metric, result);
  // }

  return result;
}

export function calcDiff(v1, v2, metric) {
  let d = ((v1 - v2) / v2) * 100;
  let r = {
    current: v1,
    prev: v2,
    diff: d ? round(d, metric.toFixe) : 0,
  };
  if (metric.goodIfGoDown) {
    r.good = r.diff <= 0 ? '--good' : '--bad';
  } else {
    r.good = r.diff <= 0 ? '--bad' : '--good';
  }
  r.direction = r.diff <= 0 ? '--down' : '--up';
  return r;
}
export function calcLocalMetric(
  data,
  metric,
  allObject = true,
  direct = false,
  metrics
) {
  // console.log("calc local object ", data, metric, allObject, direct, metrics);
  if (!direct) {
    let r = {};
    let s1 = metric.operation.items[0];
    let s2 = metric.operation.items[1];

    let m1 = data[s1.src].metrics.find((f) => f.name === s1.name);
    let m2 = data[s2.src].metrics.find((f) => f.name === s2.name);

    // console.log(s1, s2, m1, m2);

    let current_lot1 = data[s1.src].subSample.current;
    let current_lot2 = data[s2.src].subSample.current;
    let current_t1 = calcMetric(current_lot1, m1);
    let current_t2 = calcMetric(current_lot2, m2);

    let current = 0;
    let operation = metric.operation.action;
    if (operation === 'divide') {
      if (m1.convert === 'micros') {
        current_t1 = formatNumber(current_t1 / 1000000);
      }
      if (m2.convert === 'micros') {
        current_t2 = formatNumber(current_t2 / 1000000);
      }
      current = current_t2 / current_t1;
    } else if (operation === 'pourcentage') {
      current = (current_t2 / current_t1) * 100;
    } else if (operation == 'dropout') {
      current = (1 - current_t2 / current_t1) * 100;
    } else if (operation == 'cpm') {
      current = current_t2 / (current_t1 / 1000);
    }

    if (!allObject) return current;

    let prev_lot1 = data[s1.src].subSample.prev;
    let prev_lot2 = data[s2.src].subSample.prev;
    let prev_t1 = calcMetric(prev_lot1, m1);
    let prev_t2 = calcMetric(prev_lot2, m2);
    let prev = 0;
    if (operation === 'divide') {
      if (m1.convert === 'micros') {
        prev_t1 = formatNumber(prev_t1 / 1000000);
      }
      if (m2.convert === 'micros') {
        prev_t2 = formatNumber(prev_t2 / 1000000);
      }
      prev = prev_t2 / prev_t1;
    } else if (operation === 'pourcentage') {
      prev = (prev_t2 / prev_t1) * 100;
    } else if (operation == 'dropout') {
      prev = (1 - prev_t2 / prev_t1) * 100;
    } else if (operation == 'cpm') {
      prev = prev_t2 / (prev_t1 / 1000);
    }

    r.prev = round(prev, metric.toFixe);
    r.current = round(current, metric.toFixe);
    r = calcDiff(r.current, r.prev, metric);

    return r;
  } else {
    let i1m = metrics.find((f) => f.name === metric.operation.items[0].name);
    let i2m = metrics.find((f) => f.name === metric.operation.items[1].name);

    let lc1 = calcMetric(data, i1m);
    let lc2 = calcMetric(data, i2m);

    if (i1m.convert === 'micros') {
      lc1 = formatNumber(lc1 / 1000000);
    }
    if (i2m.convert === 'micros') {
      lc2 = formatNumber(lc2 / 1000000);
    }

    let result;
    if (metric.operation.action === 'divide') {
      result = lc2 / lc1;
    } else if (metric.operation.action === 'pourcentage') {
      result = (lc2 / lc1) * 100;
    } else if (metric.operation.action == 'cpm') {
      result = lc2 / (lc1 / 1000);
    }
    return round(result, metric.toFixe);
  }
}

function add(array, index, ref) {
  let sum = 0;
  for (let e of array) {
    sum += Number(e.metrics[index]);
  }
  return sum;
}

function average(array, index, ref) {
  if (ref >= 0) return averageRef(array, index, ref);
  let sum = 0;
  let i = 0;
  for (let e of array) {
    if (Number(e.metrics[index]) > 0) {
      sum += Number(e.metrics[index]);
      i++;
    }
  }
  return sum / i;
}

function averagePerLot(array, index, ref) {
  if (ref >= 0) return averagePerLotRef(array, index, ref);
  let sum = 0;
  let i = 0;
  for (let e of array) {
    sum += Number(e.metrics[index]);
    i++;
  }
  return sum;
}

function averagePerLotRef(array, index, ref) {
  let sum = 0;
  let refSum = 0;
  for (let i in array) {
    let e = array[i];
    refSum += Number(e.metrics[ref]);
    sum += Number(e.metrics[index]) * Number(e.metrics[ref]);
  }
  return sum / refSum;
}

function averageRef(array, index, ref) {
  let sum = 0;
  let refSum = 0;
  for (let e of array) {
    let refVal = Number(e.metrics[ref]);
    let val = Number(e.metrics[index]);
    if (refVal) {
      refSum += Number(e.metrics[ref]);
      sum += Number(e.metrics[index]);
    }
  }
  return sum / refSum;
}

export function getMetricIndex(all, name) {
  if (!name) return;
  return all.columnHeader.metrics.findIndex((metric) => {
    return metric === name;
  });
}

export function getDimensionIndex(all, name) {
  return all.columnHeader.dimensions.findIndex((dim) => {
    return dim === name;
  });
}

export function chartify({ data, src, dimension, metric, ref, range }) {
  let all = data[src].all;
  let datas = data[src].subSample.current;

  if (!metric) return;
  if (!datas.length) return { brut: {}, data: [], labels: [], sorted: [] };
  let o = {
    labels: [],
    data: [],
    brut: {},
  };

  if (typeof dimension === 'object') {
    if (range) {
      let duration = getDuration(dimension.start, dimension.end, range);
      for (let i = 0; i < duration.value; i++) {
        let d = dayjs(dimension.start).add(i, range).format('YYYY-MM-DD');
        // console.log('day => ', d)
        if (range === 'week') {
          d = dayjs(dimension.start).add(i, range).day(1).format('YYYY-MM-DD');
        }
        if (range === 'month') {
          d = dayjs(dimension.start)
            .add(i, range)
            .startOf(range)
            .format('YYYY-MM-DD');
        }
        if (range === 'year') {
          d = dayjs(dimension.start)
            .add(i, range)
            .dayOfYear(1)
            .format('YYYY-MM-DD');
        }
        o.brut[d] = [];
        // console.log('D => ', dimension.start, d)
      }
    } else {
      for (let i = 0; i < dimension.duration.value; i++) {
        let d = dayjs(dimension.start)
          .add(i, dimension.duration.unit)
          .format('YYYY-MM-DD');
        o.brut[d] = [];
      }
    }
    for (let data of datas) {
      let srcDate = dayjs(data.dimensions[0]).format('YYYY-MM-DD');

      if (o.brut[srcDate]) {
        o.brut[srcDate].push(data);
      }
    }

    let displayFormat = getDateFormat(range) || 'DD/MM';

    // console.log('brut => ', o.brut)

    for (let date in o.brut) {
      o.labels.push(dayjs(date).format(displayFormat));
      if (typeof metric.operation === 'object') {
        let lot1 = data[metric.operation.items[0].src].subSample.current.filter(
          (f) => {
            let diff = dayjs(f.dimensions[0]).diff(dayjs(date), 'day');
            return diff === 0 ? f : false;
          }
        );
        let lot2 = data[metric.operation.items[1].src].subSample.current.filter(
          (f) => {
            let diff = dayjs(f.dimensions[0]).diff(dayjs(date), 'day');
            return diff === 0 ? f : false;
          }
        );
        let m1 = data[metric.operation.items[0].src].metrics.find(
          (f) => f.name === metric.operation.items[0].name
        );
        let m2 = data[metric.operation.items[1].src].metrics.find(
          (f) => f.name === metric.operation.items[1].name
        );
        let t1 = calcMetric(lot1, m1);
        let t2 = calcMetric(lot2, m2);
        let current = (t2 / t1) * 100;

        o.data.push(convert(current, metric));
      } else {
        const result =
          convert(calcMetric(o.brut[date], metric, ref), metric, true) || 0;
        o.data.push(result);
      }
    }
  } else {
    // Dimension Entry
    let dimIndex = getDimensionIndex(all, dimension);

    o.sorted = [];
  }

  // console.log('result chartify ', o)

  return o;

  let total = o.data.reduce((a, b) => Number(a) + Number(b));

  return {
    ...o,
    data: o.data.map((m) => {
      return round((m / total) * 100, 2);
    }),
    sorted: o.sorted.map((m) => {
      return {
        name: `${m.name} (%)`,
        value: round((m.value / total) * 100, 2),
      };
    }),
  };
}

export function tableSort(source, sortObj) {
  if (!source) return;
  let result = source.sort(function (a, b) {
    let aSorter = a.find(
      (f) => f.metric === sortObj.src && f.type === sortObj.type
    );
    let bSorter = b.find(
      (f) => f.metric === sortObj.src && f.type === sortObj.type
    );
    if (isNaN(aSorter.val)) return 1;
    if (isNaN(bSorter.val)) return -1;
    if (sortObj.order === 'ASC') {
      return bSorter.val - aSorter.val;
    } else {
      return aSorter.val - bSorter.val;
    }
    // return 0;
  });
  return sortObj.limit ? result.splice(0, sortObj.limit) : result;
}

export function createTable(o) {
  let table = {};
  table.head = tableHead(o);
  table.body = tableBody(o);
  let totalBase = tableFoot(table, o);
  for (let l in table.body) {
    let line = table.body[l];
    for (let c in line) {
      let metric = o.metrics.find((m) => m.name === line[c].metric);
      if (line[c].type === 'current') {
        let total = Number(totalBase[line[c].metric].current);
        let pourcentTotal = Number(round((line[c].val / total) * 100, 0));
        line[c].total = total;
        line[c].pourcentTotal = pourcentTotal;
        line[c].unit = metric.unit;
      }
      if (
        metric &&
        metric.pourcentageTotal &&
        Number.isInteger(line[c].pourcentTotal)
      ) {
        let totalCol = {
          type: 'total',
          val: line[c].pourcentTotal,
        };
        line.splice(Number(c) + 1, 0, totalCol);
      }
    }
  }
  table.foot = [];
  if (!o.calcTotal) return table;
  for (let t in totalBase) {
    let metric = o.metrics.find((m) => m.name === t);
    if (metric.unit === '€') {
      metric.unit = o.currency || '€';
    }
    let current = `${totalBase[t].current}`;
    if (metric.convert && metric.operation.action != 'divide') {
      table.foot.push({
        value: convert(current, metric) + metric.unit,
        metric: metric,
      });
    } else {
      table.foot.push({
        value: `${formatNumber(round(current, metric.toFixe))}${metric.unit}`,
        metric: metric,
      });
    }

    if (metric.pourcentageTotal)
      table.foot.push({ value: '100%', metric: metric });
    if (metric.compare && o.delta) {
      let pourcentageDelta = round(
        ((totalBase[t].current - totalBase[t].prev) / totalBase[t].prev) * 100,
        1
      );
      if (totalBase[t].prev === 0) {
        table.foot.push({ value: 0, metric: metric });
      } else {
        table.foot.push({
          value: `${pourcentageDelta}%`,
          metric: metric,
        });
      }
    }
  }

  return table;
}

function tableHead(o) {
  let head = [];
  for (let i in o.cols) {
    head.push(o.cols[i]);
    // console.log("thead => ", o.cols[i]);
    if (o.cols[i].pourcentageTotal) head.push('% total sessions');
    if (o.cols[i].compare && o.delta)
      head.push({ title: '% Δ', name: o.cols[i].name });
  }
  return head;
}

function tableBody(o) {
  // console.log('Table body => ', o);
  let body = [];
  for (let l in o.lines) {
    let line = o.lines[l].name;

    let displayDims = o.sample.current.find((f) => {
      if (f.dimensions.includes(line)) return f;
    });
    // let obj = { val: line, type: "name" };
    let lineArray = [{ name: line, type: 'name', isColTitle: true }];
    if (displayDims && displayDims.dimensions) {
      for (let i in displayDims.dimensions) {
        let key = o.dimensions[i].name;
        lineArray[0][key] = displayDims.dimensions[i];
      }
    }

    body.push(lineArray);
    let subSample = {
      prev: [],
      current: [],
    };
    let dataRef = o.sample;
    // console.log("DATA REF ", dataRef, o.all);
    // console.log("SHOULD DIRECT ? ", o);
    if (!o.direct) {
      // dataRef = o.all;
      subSample = {
        prev: o.sample.prev.filter((d) => {
          return d.dimensions.find((f) => f === line);
        }),
        current: o.sample.current.filter((d) => {
          return d.dimensions.find((f) => f === line);
        }),
      };
    } else {
      // dataRef = o.sample;
      subSample = {
        prev: [],
        // current: o.sample.data
        current: o.sample.data.filter((d) => {
          return d.dimensions.find((f) => f === line);
        }),
      };
    }

    for (let c in o.cols) {
      let metricObj = { ...o.metrics.find((f) => f.name === o.cols[c].name) };

      let current = Number(
        calcMetric(subSample.current, metricObj, null, o.metrics)
      );
      // if (metricObj.name === 'objective_rate') {
      //   console.log('metric ', subSample.current, current);
      // }

      let prev = Number(calcMetric(subSample.prev, metricObj, null, o.metrics));
      let diff = Number(round((current * 100) / prev - 100, 0));

      if (diff == 'Infinity') diff = 0;
      body[l].push(
        {
          val: current,
          type: 'current',
          metric: o.cols[c].name,
          prev: prev,
          diff: diff,
        }
        // { val: diff, type: "diff", metric: cols[c].name }
      );
      // debugger;
      if (metricObj.compare && o.delta) {
        body[l].push({
          val: diff,
          type: 'diff',
          metric: o.cols[c].name,
        });
      }
    }
  }

  return cleanTableBody(body);
  return o.filter
    ? filterTableBody(cleanTableBody(body), o.filter, o.displaySupport)
    : cleanTableBody(body);
  // return cleanTableBody(body);
  return filterTableBody(cleanTableBody(body), filter);
}
function tableFoot(table, o) {
  let total = {};
  for (let c in o.cols) {
    total[o.cols[c].name] = {
      prev: 0,
      current: 0,
    };
    let metricObj = { ...o.metrics.find((f) => f.name === o.cols[c].name) };

    let nbLines = 0;
    for (let d in table.body) {
      let sampleForTotal = table.body[d].find((s) => {
        return s.metric === o.cols[c].name;
      });

      let prevVal = sampleForTotal.prev;
      let currentVal = sampleForTotal.val;

      if (!isNaN(currentVal)) {
        total[o.cols[c].name].current =
          total[o.cols[c].name].current + currentVal;
        nbLines++;
      }

      // currentVal = isNaN(currentVal) ? 0 : currentVal;
      if (!isNaN(prevVal)) {
        total[o.cols[c].name].prev = total[o.cols[c].name].prev + prevVal;
      }
      // total[cols[c]].current = total[cols[c]].current + currentVal;
      // total[cols[c]].diff = total[cols[c]].current + currentVal;
    }
    if (metricObj.operation != 'add') {
      // let nbLines = table.body.length - 1;
      total[o.cols[c].name].prev = Number(
        round(total[o.cols[c].name].prev / nbLines, 2)
      );
      total[o.cols[c].name].current = Number(
        round(total[o.cols[c].name].current / nbLines, 2)
      );
    }
    // total[cols[c]].diff = Number(
    //   round((total[cols[c]].current / total[cols[c]].prev) * 100 - 100, 2)
    // );
  }
  return total;
}

function cleanTableBody(body) {
  for (let d in body) {
    let lineHasValue = false;
    if (
      body[d][0].val === '(none)' ||
      body[d][0].val === '(not set)' ||
      body[d][0].val === '(direct)'
    )
      delete body[d];
    for (let e in body[d]) {
      if (body[d][e].type === 'current' && !isNaN(body[d][e].val)) {
        if (body[d][e].val != 0) lineHasValue = true;
      }
    }
    if (!lineHasValue) delete body[d];
  }
  return body;
}

export function dimsNames(all, rows) {
  let dimsNames = {};
  for (let dim in all.columnHeader.dimensions) {
    let key = all.columnHeader.dimensions[dim];
    dimsNames[key] = dimsArray(rows, dim);
    // console.log(key, rows, dimsNames[key])
  }
  return dimsNames;
}

function dimsArray(rows, index) {
  let dimsArray = [];
  for (let row in rows) {
    let name = rows[row].dimensions[index];
    dimsArray.push(name);
  }
  dimsArray.sort();
  var count = {};
  dimsArray.forEach(function (i) {
    count[i] = (count[i] || 0) + 1;
  });
  let dimsObj = [];
  for (let j in count) {
    dimsObj.push({
      name: j,
      count: count[j],
    });
  }
  return dimsObj;
}

export function filterDims(all, rows, dims, current) {
  if (!dims) return rows;
  let filteredDims = [...rows];
  for (let dim in dims) {
    filteredDims = filteredDims.filter((r) => {
      if (dims[dim].indexOf(r.dimensions[getDimensionIndex(all, dim)]) >= 0)
        return true;
    });
  }
  return filteredDims;
}

export function joinPaginateObject(obj) {
  let result = {
    columnHeader: {},
    data: {
      prev: [],
      current: [],
    },
  };
  for (const [key, value] of Object.entries(obj)) {
    result.columnHeader = value.columnHeader;
    result.data.prev = result.data.prev.concat(value.data.prev);
    result.data.current = result.data.current.concat(value.data.current);
  }
  return result;
}

export function cleanData(raw) {
  return raw.map((m) => {
    return {
      dimensions: m.dimensions,
      metrics: m.metrics.map((metric) => {
        return String(metric).replace('%', '');
      }),
    };
  });
}

export function cleanRaw(raw) {
  return {
    total: raw.total,
    filters: raw.filters,
    targeting: raw.targeting,
    count: raw.count,
    extensions: raw.extensions,
    columnHeader: raw.columnHeader,
    data: {
      prev: raw.data.prev ? cleanData(raw.data.prev) : [],
      current: raw.data.current ? cleanData(raw.data.current) : [],
    },
    hasFacets: !raw.filters,
    members: raw.members ? raw.members : 0,
  };
}

export function getDayRange(now, range) {
  if (!dayjs(now).isValid()) return;
  const FirstDayOfCurrentMonth = dayjs(now)
    .startOf(range)
    .startOf('week')
    .format();
  const lastDayOfCurrentMonth = dayjs(now).endOf(range).endOf('week').format();
  const dayCount = getDuration(FirstDayOfCurrentMonth, lastDayOfCurrentMonth)
    .value;

  let result = [];
  for (let i = 0; i < dayCount; i++) {
    const current = dayjs(FirstDayOfCurrentMonth).add(i, 'day');

    result.push({
      day: {
        short: current.format('dd'),
        long: current.format('dddd'),
      },
      date: current.format(),
      currentMonth: current.format('M'),
      isCurrentMonth: current.isSame(now, 'month'),
    });
  }
  return result;
}

export function getGeoPointer({ raw, store, id, display }) {
  const dimensions = getDimensionIndex(store.all, 'lat');
  const lat = raw.dimensions[getDimensionIndex(store.all, 'lat')];
  const lng = raw.dimensions[getDimensionIndex(store.all, 'lng')];

  let pointer = {
    id: id,
    latlng: latLng(lat, lng),
    metrics: [],
  };

  display.forEach((metric) => {
    let metricIndex = getMetricIndex(store.all, metric);
    pointer.metrics.push({
      name: metric,
      value: raw.metrics[metricIndex],
    });
    // pointer[metric] = raw.metrics[metricIndex]
  });

  return pointer;
}

export function mapId({ value, expose, exposeMapper, data }) {
  if (!exposeMapper) return value;
  const mapperDimIndex = getDimensionIndex(data.all, exposeMapper);
  const mappedDimIndex = getDimensionIndex(data.all, expose);
  const findDimId = data.subSample.current.find((f) => {
    return f.dimensions[mappedDimIndex] === value;
  });
  return findDimId ? findDimId.dimensions[mapperDimIndex] : value;
}

export function excerpt(source, max = 50, end = ' ...') {
  if (!source) return;
  let s = source.split('');
  return s.length > max ? s.splice(0, max).join('') + end : s.join('');
}

export function getSocialDescription(source, max = 50) {
  return excerpt(source.about || source.profile?.description, max);
}

export function localDataId(src) {
  let id = `${src.src.action}-${src.prev.start}-${src.prev.end}-${src.current.start}-${src.current.end}`;
  const filters = src.src.filters;
  if (filters) {
    Object.entries(filters).forEach((m) => {
      id += m[1].length ? `-${m[0]}:${m[1].join('_')}` : '';
    });
  }
  return slugify(id.toLowerCase());
}

export function uppercaseFirstLetter(s) {
  if (typeof s != 'string') return;
  const letters = s.split('');
  letters[0] = letters[0].toUpperCase();
  return letters.join('');
}

export function stringPrefix(string, prefix, camelcase = true) {
  return camelcase
    ? uppercaseFirstLetter(prefix) + uppercaseFirstLetter(string)
    : prefix + string;
}

export async function crawl(url, options) {
  console.log('* crawling ', url, options);
  let src;
  let u = `${options.proxy}?url=${url}`;
  if (options.token) {
    u += `&token=${options.token}`;
  }
  try {
    src = (await axios.get(u).then((r) => r)).data;
  } catch (e) {
    throw e;
  }
  let parser = new DOMParser();
  let html = parser.parseFromString(src, 'text/html');
  const title = html.head.getElementsByTagName('title')[0].innerText;
  const metas = html.head.getElementsByTagName('meta');

  const description = Array.from(metas)
    .filter((f) => f.name === 'description')
    .map((m) => m.content)[0];

  const og = Array.from(metas)
    .filter((f) => {
      const attrs = Array.from(f.attributes).filter(
        (g) => g.name === 'property'
      );
      const openGraph = attrs.filter((h) => h.nodeValue.includes('og:'));
      if (openGraph.length) return f;
    })
    .map((m) => {
      const prop = Array.from(m.attributes).filter(
        (f) => f.name === 'property'
      );
      const openGraph = prop.map((h) => h.nodeValue);
      return {
        key: openGraph[0],
        content: m.content,
      };
    });

  let image = og.find((f) => f.key === 'og:image');

  if (!image) {
    const imgs = html.body.getElementsByTagName('img');
    if (imgs.length) {
      let ObjUrl = new URL(imgs[0].attributes[0].nodeValue, url);
      image = {
        key: 'og:image',
        content: ObjUrl.href,
      };
    }
  }

  let result = {
    title: title,
    description: description,
    url: url,
    image: image?.content,
  };
  console.log('crawling result => ', result);
  return result;
}

export function isUrl(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return regex.test(str);
  if (!regex.test(str)) {
    return false;
  } else {
    return true;
  }
  // return;
  // is;
  // var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  // return (res !== null)
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
  // var pattern = new RegExp(
  //   "^(https?:\\/\\/)?" + // protocol
  //   "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  //   "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  //   "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  //   "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  //     "(\\#[-a-z\\d_]*)?$",
  //   "i"
  // ); // fragment locator
  // return !!pattern.test(str);
}

function lorem() {
  console.log('lorem lorem');
}

export function _b(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function unInjectRelation(src, key) {
  let result;
  if (!Array.isArray(src)) {
  } else {
    result = src.map((m) => {
      let o = { ...m };
      if (o[key]) o[key] = o[key].id;
      return o;
    });
  }

  return result;
}
export function injectRelation(src, provide, key, isArray = true) {
  if (!Array.isArray(src)) {
    let newSrc = src;
    const newKeyId = src[key].id;
    const find = provide.find((f) => f.id === newKeyId);
    newSrc[key] = find;
    return newSrc;
  }
  return src.map((m) => {
    let k;
    if (Array.isArray(m[key])) {
      k = m[key].map((s) => {
        return provide.find((f) => Number(f.id) === Number(s));
      });
    } else {
      k = provide.find((f) => Number(f.id) === Number(m[key]));
    }
    return {
      ...m,
      [key]: k,
    };
  });
}

export function getAllComments(src) {
  return getComments(src.comments, []);
}

function getComments(src, acc) {
  let result = [];
  if (!src || !src.length) return acc;
  for (let comment of src) {
    result.push(comment);
    result = result.concat(getComments(comment.replies, acc));
  }
  return acc.concat(result);
}

export function uniqArray(a, k = 'id') {
  return [...new Set(a.map((m) => m[k]))].map((m) => {
    return a.find((f) => f[k] === m);
  });
}

export function localLikePost(post) {
  // let result =
  // console.log("local like post => ", post);
  return { ...post, has_liked: 1 };
}

export function findGetPath(id, source, acc = {}) {
  if (!acc.path) acc.path = '';
  if (!source) return acc;
  // console.log("source => ", source, " id => ", id, acc);
  // let result = {};

  let findIndex;

  if (Array.isArray(source)) {
    findIndex = source.map((m) => m.id).indexOf(id);
    // let find = source.find((f, i) => {
    //   if(f.id === id) return { index: i, item: f };
    // });
    if (findIndex > -1) {
      acc.path += `/${findIndex}`;
      acc.data = source[findIndex];
    } else {
      source.forEach((e, i) => {
        // console.log("each ", e, i);
        // acc.path += '/comments'
        findGetPath(id, e, acc);
      });
    }
    // console.log("acc => ", acc);
  } else {
    console.log('try to seek ', source);
    let target = source.comments ? 'comments' : 'replies';
    let findSubIndex;
    if (source.comments) {
      findSubIndex = source[target].map((m) => m.id).indexOf(id);
      if (findSubIndex > -1) {
        acc.path += `/${target}/${findSubIndex}`;
        acc.data = source[target][findSubIndex];
      } else {
        source[target].forEach((e, i) => {
          console.log('each ', e, i);
          // acc.path += '/comments'
          findGetPath(id, e, acc);
        });
      }
      // console.log("SUBINDEX => ", findSubIndex);
    }
    // let findSubIndex = source.
    // array.forEach(element => {
    // });
    // findGetPath(id, )
  }

  return acc;

  source.find((e, i) => {
    if (!acc.path) acc.path = '';
    // console.log(e, i);

    if (e.id === id) {
      acc.path = `${acc.path}/${i}`;
      acc.data = e;
    } else {
      // let oldPath = acc.path; //.split("/"); //.slice(0, 4);
      // oldPath = oldPath.slice(0, oldPath.length - 1).join("/");
      // console.log("OLD pah ", acc.path, oldPath);
      // acc.path = acc.path.split('/').pop().join('/');
      // console.log("should remove last ", acc.path);
      const target = e.comments ? 'comments' : 'replies';
      acc.path = `${acc.path}/${target}`;
      acc.path = `${removeLastHash(acc.path)}`;
      findGetPath(id, e.comments || e.replies, acc);
    }
  });

  return acc;
  source.forEach((e, i) => {
    if (!acc.path) acc.path = '';
    // acc.path = acc.path +
    console.log('e ', e, ' i ', i);
    if (e.id === id) {
      acc.path = `${acc.path}/${i}`;
      acc.data = e;
    } else {
      findGetPath(id, e.comments || e.replies, acc);
    }
  });
  // let find = posts.find((f, i) => f.id === id);
  // if (!find) {
  //   find = findGetPath(id, posts.comments);
  // }
  return acc;
  return {
    id: id,
    posts: posts,
    find: find,
  };
}

function removeLastHash(hash) {
  let a = hash.split('/');
  a.splice(-1, 1);
  // let b = a.splice(-1,1);
  // console.log("hash => ", a, b);
  return a.join('/');
}

export function filterSocialMessage(message, rules, network) {
  if (!message) return '';
  let result = message;
  // console.log("should escape => ", message, rules);
  // const RegEscape = new RegExp()
  if (rules) {
    const RulesRegex = new RegExp(rules.source, 'g');
    result = result.replace(
      RulesRegex,
      `<span class="${rules.class}">${rules.prefix}${rules.source}</span>`
    );
  }
  return filtersSocial(result, network);
}

export function filtersSocial(message, network) {
  return filterSocialHashtags(filterSocialUrls(message), network);
}

export function manageGmbTranslation(message) {
  // TODO
  let result = '';
  // console.log("manage translation ", message.split(''));
  if (message.includes('(Original)')) {
    // console.log('')
    result += message.split('(Original)')[1];
    result +=
      '<div class="k__monitoring__preview__message__translate"><h4>Google translate</h4>';
    result += message.split('(Original)')[0].split('(Translated by Google)')[1];
    result += '</div>';
    // return message.split('(Original)')[1]
    // return '<h1>lolo</h1>'
  } else {
    result += message.split('(Translated by Google)')[0];
    result +=
      '<div class="k__monitoring__preview__message__translate"><h4>Google translate</h4>';
    result += message.split('(Translated by Google)')[1];
    result += '</div>';
  }
  return result;
}

export function filterSocialHashtags(message, network) {
  if (!message) return message;
  let result = message;
  const RulesRegex = /#(\w+)/g;
  let link;
  if (network === 'FACEBOOK') {
    link = `<a href="https://www.facebook.com/hashtag/$1" class="k__social__message__url">#$1</a>`;
  } else if (network === 'INSTAGRAM') {
    link = `<a href="https://www.instagram.com/explore/tags/$1" class="k__social__message__url">#$1</a>`;
  } else if (network === 'none') {
    link = `<a class="k__social__message__url">#$1</a>`;
  } else {
    link = `#$1`;
  }
  result = result.replace(RulesRegex, link);
  return result;
}

export function filterSocialUrls(message) {
  if (!message) return message;
  let result = message;
  const RulesRegex = /(https?:[^\s]+)/g;
  result = result.replace(
    RulesRegex,
    `<a href="$1" target="_blank" rel="noopener" class="k__social__message__url">$1</a>`
  );
  return result;
}

export function timeStampify(src, updated) {
  const ts = Date.now();
  const k = 'lastUpdate';
  if (!Array.isArray(src)) {
    return {
      ...src,
      [k]: ts,
      updated: updated || false,
    };
  }
  return src.map((m) => {
    return {
      ...m,
      [k]: ts,
      updated: updated || false,
    };
  });
}

export function filterPDV({ data, filters }) {
  const result = [];
  if (!filters.value || !filters.value.length) return data;
  if (filters.type === 'network') {
    const uppercasedValues = filters.value.map((m) => m.toUpperCase());
    const networkFilteredData = data.map((m) => {
      const networks = m.networks.filter((f) =>
        uppercasedValues.includes(f.type.toUpperCase())
      );
      return { ...m, networks: networks };
    });
    return networkFilteredData.filter((f) => f.networks && f.networks.length);
  }
  data.forEach((f) => {
    const html = {};
    const find = Object.entries(f).filter((filter) => {
      if (!filter[1]) return false;

      if (
        filters.fields.includes(filter[0]) &&
        lookingFor(filters.value, filter[1])
      ) {
        const reg = new RegExp('(' + filters.value + ')', 'gi');
        if (typeof filter[1] === 'object') {
          html[filter[0]] = filter[1].map((m) => {
            return m.replace(
              reg,
              '<span class="k__search__highlight">$1</span>'
            );
          });
        } else {
          html[filter[0]] = filter[1].replace(
            reg,
            '<span class="k__search__highlight">$1</span>'
          );
        }
        return true;
      }
      // if (filter[0] === 'networks') {
      //   console.log('filter networks ', filter[1], filters.value);
      // }
    });
    if (!find.length) return;
    result.push({ ...f, html: html });
  });
  return result;
}

export function lookingFor(string, where, trueIfNoWhere = true) {
  if (!where && trueIfNoWhere) return true;
  if (!where) return false;
  let found = String(where).toUpperCase().includes(string.toUpperCase());
  if (!found) return;
  return found;
}

function filterListsearch({ data, filter }) {
  if (!filter.value) return data;
  let result = [];
  let filteredData = data;
  // if (direct) {
  //   filteredData = [{ id: 'test', content: data }];
  // }
  // console.log('filteredData ', filteredData, filter);
  filteredData.forEach((f) => {
    let i = [];
    let exist = f.content.filter((item, index) => {
      if (filter.fields.includes(item.name)) {
        let ok = lookingFor(filter.value, item.label || item.content);
        if (ok) {
          i.push(index);
          return ok;
        }
      }
    });
    if (!exist.length) return;
    let n = { ...f };
    n.content = n.content.map((m) => {
      let n = m;
      delete n.html;
      return n;
    });
    var reg = new RegExp('(' + filter.value + ')', 'gi');

    i.forEach((j) => {
      let target = n.content[j].label || n.content[j].content;
      n.content[j] = {
        ...n.content[j],
        html: target.replace(
          reg,
          '<span class="k__search__highlight">$1</span>'
        ),
      };
    });
    result.push({ ...n });
  });
  return result;
}

function timeInRange({ time, range }) {
  let start = dayjs().startOf(range).format();
  let today = dayjs().format();
  let timeToCheck = dayjs(time).format();
  return isBetween(timeToCheck, start, today);
}

function filterListstatus({ data, filter }) {
  if (filter.value.name === 'all') return data;
  return data.filter((f) => {
    let status = f.content.find((g) => g.name === 'status');
    return status.content === filter.value.name;
  });
}

function filterListtime({ data, filter }) {
  return data.filter((f) => {
    let field = f.content.find((find) => find.name === filter.field);
    return timeInRange({ time: field.content, range: filter.value.name })
      ? f
      : false;
  });
}

export function filterList({ data, filters, direct }) {
  let filtered = [...data];
  for (let k in filters) {
    let f = eval(`filterList${k}`);
    filtered = f({ data: filtered, filter: filters[k], direct });
  }
  return filtered;
}
// function sortListTime(){}

export function sorterList({ data, sorter }) {
  if (!data) return [];
  if (!data.length) return [];
  let result = data.sort((a, b) => {
    let fieldA = a.content.find((f) => f.name === sorter.field)['content'];
    let fieldB = b.content.find((f) => f.name === sorter.field)['content'];
    if (!sorter.type) {
      return sorter.order === 'ASC'
        ? fieldA.localeCompare(fieldB)
        : fieldB.localeCompare(fieldA);
    }
    if (sorter.type === 'time') {
      if (sorter.order === 'ASC') {
        return dayjs(fieldA).isBefore(dayjs(fieldB)) ? 1 : -1;
      } else {
        return dayjs(fieldA).isBefore(dayjs(fieldB)) ? -1 : 1;
      }
    }
  });
  return result;
}

export function checkVideoDuration({ file, min = 0, max = 9999999 }) {
  return new Promise((resolve, reject) => {
    let video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function (event) {
      const duration = event.currentTarget.duration;

      if (duration < min) {
        resolve('video_min');
        return;
      } else if (duration > max) {
        resolve('video_max');
        return;
      }
      resolve(false);
    };
    video.src = file;
  });
}

export function getViewPort() {
  let viewport = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  return viewport;
}

export function readMedia(media) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    // console.log('will read media ', media, typeof media, media instanceof Blob);
    if (media instanceof Blob) reader.readAsDataURL(media);
  });
}

export function blobType(blob) {
  const a = blob.split(';');
  if (a[0].includes('image')) return 'image';
  if (a[0].includes('video')) return 'video';
}

export function arraymove(arr, fromIndex, toIndex) {
  let result = [...arr];
  var element = result[fromIndex];
  result.splice(fromIndex, 1);
  result.splice(toIndex, 0, element);
  return result.map((m, i) => {
    return { ...m, index: i };
  });
}

export function tempMessage(message = {}) {
  return {
    _id: nanoid(),
    ...message,
  };
}

export function installColors(colors) {
  // Set css vars
  for (let i in colors) {
    const color = new TinyColor(colors[i]);

    if (color) {
      const highlight = color.brighten(10).saturate(10).toHexString();
      const softDark = color.darken(20).toHexString();
      const darker = color.darken(35).toHexString();
      const light = color.lighten(55).saturate(55).toHexString();
      const softLight = color.lighten(35).toHexString();

      const alpha = color.setAlpha(0);
      // const alphaHalf = color.setAlpha()

      const isLight = color.isLight();
      const readable = isLight
        ? color.darken(100).toHexString()
        : color.lighten(100).toHexString();

      const link = isLight ? softDark : color.toHexString();

      let selector;

      if (i === 'banner') {
        selector = isLight ? readable : color.toHexString();
      }

      document.documentElement.style.setProperty(
        '--color-' + i,
        color.toHexString()
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-link',
        link
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-alpha',
        alpha
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-highlight',
        highlight
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-light',
        light
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-soft-light',
        softLight
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-soft-dark',
        softDark
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-dark',
        darker
      );
      document.documentElement.style.setProperty(
        '--color-' + i + '-readable',
        readable
      );
      if (selector) {
        document.documentElement.style.setProperty(
          '--color-' + i + '-selector',
          selector
        );
      }
    }
  }
}
