<template>
  <div class="k__modal__wrapper"
       @click="hideAlert">
    <div class="k__modal__overlay"></div>
    <div class="k__modal__content">
      <div class="k__modal__close" @click="hideAlert">&times;</div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        closable: {type: Boolean},
    },
    methods: {
        hideAlert() {
            if (this.closable) {
                this.eventHub.$emit('hideAlert');
            }
        }
    }
}
</script>

<style lang="scss">
.k__modal__close {
  position: absolute;
  top: 0;
  right: 1rem;
  font-size: 2rem;
  color: #495b61;
  cursor: pointer;
}
</style>
