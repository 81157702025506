<template>
  <form class="bdc-extranet__form bdc-message-edit"
        method="post"
        enctype="multipart/form-data"
        @submit="loading = true">
    <input type="hidden" name="id" v-model="id"/>

    <div class="bdc-extranet__form__widget">
      <label for="name">Nom *</label>
      <input type="text" id="name" name="name" v-model="name" required/>
    </div>

    <fieldset>
      <legend>Statut</legend>

      <div style="display: flex;">
        <div class="bdc-extranet__form__widget">
          <label for="state">Mode *</label>
          <select id="state" name="state" v-model="state" required>
            <option value="auto" selected>Automatique (dates)</option>
            <option value="active">Actif forcé</option>
            <option value="inactive">Inactif forcé</option>
          </select>
        </div>

        <div class="bdc-extranet__form__widget">
          <label for="start">Date début *</label>
          <input type="date" id="start" name="start" v-model="start" required/>
        </div>

        <div class="bdc-extranet__form__widget">
          <label for="end">Date fin</label>
          <input type="date" id="end" name="end" v-model="end"/>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Identifiants clients</legend>

      <div class="bdc-extranet__form__widget">
        <label for="clients">Liste d'identifiants séparés par des virgules</label>
        <textarea id="clients" name="clients" v-model="clients"></textarea>
      </div>

      <div class="bdc-extranet__form__widget">
        <label for="clientsFile">Remplir avec la première colonne du fichier CSV</label>
        <input type="file" id="clientsFile" @change="clientsCsvToClientsText" accept=".csv"/>
      </div>

      <p class="k__alert --danger" v-if="csvError">{{ csvError }}</p>
    </fieldset>

    <fieldset>
      <legend>Contenu</legend>

      <div class="bdc-extranet__form__widget">
        <label for="contentType">Couleur de fond (code hexadécimal) *</label>
        <div style="display: flex;">
          <input type="text" id="color" name="color" v-model="color" placeholder="#0b8db0" @change="validateColor" required/>
          <div class="bdc-message-edit__colorpreview" :style="{'background-color': color}"></div>
        </div>
      </div>

      <div class="bdc-extranet__form__widget">
        <label for="contentType">Type</label>
        <select id="contentType" name="contentType" v-model="contentType">
          <option value="html" selected>HTML</option>
          <option value="image">Image</option>
          <option value="video">Vidéo</option>
        </select>
      </div>

      <template v-if="contentType === 'html'">
        <div class="bdc-extranet__form__widget">
          <label for="head">Head</label>
          <textarea id="head" name="head" v-model="head"></textarea>
        </div>

        <div class="bdc-extranet__form__widget">
          <label for="body">Body *</label>
          <textarea id="body" name="body" v-model="body" required></textarea>
        </div>
      </template>

      <div class="bdc-extranet__form__widget" v-if="contentType === 'image'">
        <label for="image">Image *</label>
        <bdc-file-input id="image" accept=".jpg,.png,.jpeg,.gif" :required="!image" :max-size-m="5"/>
        <div v-if="image" class="bdc-message-edit__mediapreview">
          <img alt="Preview" :src="image" width="250"/>
        </div>
      </div>

      <div class="bdc-extranet__form__widget" v-if="contentType === 'video'">
        <label for="video">Vidéo *</label>
        <bdc-file-input id="video" accept=".mp4" :required="!video" :max-size-m="30"/>
        <div v-if="video" class="bdc-message-edit__mediapreview">
          <video width="250" controls>
            <source :src="video" type="video/mp4">
            Oups ! Votre navigateur ne peut pas afficher notre vidéo...
          </video>
        </div>
      </div>

      <div style="display: flex;" v-if="contentType === 'video' || contentType === 'image'">
        <div class="bdc-extranet__form__widget">
          <label for="maxDisplays">Largeur (px) *</label>
          <input type="number" id="width" name="width" v-model="width" step="1" min="0" placeholder="1440" required/>
        </div>
        <div class="bdc-extranet__form__widget">
          <label for="maxDisplays">Hauteur (px) *</label>
          <input type="number" id="height" name="height" v-model="height" step="1" min="0" placeholder="900" required/>
        </div>
      </div>

      <div class="bdc-extranet__form__widget">
        <label for="url">URL bouton d'action</label>
        <input type="url" id="url" name="url" v-model="url" placeholder="https://www.regicom.fr"/>
      </div>
    </fieldset>

    <fieldset>
      <legend>Trigger</legend>

      <div style="display: flex;">
        <div class="bdc-extranet__form__widget">
          <label for="trigger">Mode</label>
          <select id="trigger" name="trigger" v-model="trigger">
            <option value="" selected>A chaque session</option>
            <option value="max_displays">Un certain nombre de fois (mais 1 seule fois par session)</option>
            <option value="untill_clicked" :disabled="!url">Jusqu'à ce que le bouton d'action soit cliqué</option>
          </select>
        </div>

        <div class="bdc-extranet__form__widget" v-if="trigger === 'max_displays'">
          <label for="maxDisplays">Nombre de fois *</label>
          <input type="number" id="maxDisplays" name="maxDisplays" v-model="maxDisplays" step="1" min="1" required/>
        </div>
      </div>
    </fieldset>

    <div style="text-align: right; margin: 1rem 0;">
      <a :href="back" class="k__bton --rounded --mini">Annuler</a>
      <button type="submit" class="k__bton --rounded" :class="{'k__bton--blink': loading}">
        {{ loading ? 'Chargement en cours' : 'Sauvegarder' }}
      </button>
    </div>
  </form>
</template>

<script>
import BdcFileInput from './BdcFileInput.vue';

export default {
    components: {BdcFileInput},
    props: {
        back: {type: String, required: true},
        message: {type: Object, default: null}
    },
    data() {
        return {
            loading: false,
            id: null,
            name: null,
            state: 'auto',
            start: null,
            end: null,
            clients: null,
            color: '#0b8db0',
            contentType: 'html',
            body: null,
            head: null,
            image: null,
            video: null,
            url: null,
            trigger: '',
            maxDisplays: null,
            width: null,
            height: null,
            csvError: null,
            _colorInput: null
        }
    },
    created() {
        if (this.message) {
            this.id = this.message.id;
            this.name = this.message.name;
            this.state = this.message.state;
            this.start = this.message.start.date.substr(0, 10);
            this.end = this.message.end ? this.message.end.date.substr(0, 10) : null;

            this.clients = this.message.clients;

            this.contentType = this.message.contentType;
            this.color = this.message.color;
            this.body = this.message.body;
            this.head = this.message.head;
            this.image = this.message.image;
            this.video = this.message.video;
            this.width = this.message.width;
            this.height = this.message.height;
            this.url = this.message.url;

            if (this.message.maxDisplays) {
                this.trigger = 'max_displays';
                this.maxDisplays = this.message.maxDisplays;
            } else if (this.message.isClosable) {
                this.trigger = 'untill_clicked';
            }
        }
    },
    mounted() {
        this._colorInput = document.getElementById('color');
    },
    beforeDestroy() {
        this._colorInput = null;
    },
    methods: {
        clientsCsvToClientsText(event) {
            this.csvError = null;
            const files = event.target.files;

            if (files.length === 0) {
                return;
            }

            const reader = new FileReader();
            reader.onload = event => this._clientsCsvFileContentToClients(event.target.result);
            reader.onerror = () => this.csvError = 'Impossible de lire ce fichier';
            reader.readAsText(files[0]);
        },
        validateColor() {
            this._colorInput.setCustomValidity("");
            if (!this.color || !/^#[0-9A-F]{6}$/i.test(this.color)) {
                this._colorInput.setCustomValidity("Couleur incorrecte, veuillez saisir un code hexadécimal à 6 caractères");
            }
        },
        _clientsCsvFileContentToClients(csvFileContent) {
            const firstColumn = (candidate, delimiter) => {
                if (candidate.includes(delimiter)) {
                    return candidate.substring(0, candidate.indexOf(delimiter));
                }

                return candidate;
            };

            const cleanCandidate = candidate => firstColumn(candidate.replace(',', ';'), ';').trim().toUpperCase();
            const isClient = candidate => candidate && candidate.match(/FR-C\d+/);

            const clients = csvFileContent.split("\n").map(cleanCandidate).filter(isClient);

            if (clients.length <= 0) {
                this.csvError = 'Ce fichier ne semble pas contenir d\'identifiants clients';
                return;
            }

            this.clients = clients.join(',');
        }
    }
};
</script>

<style lang="scss">
.bdc-message-edit {
  &__mediapreview {
    margin: 1rem;
    text-align: center;
  }

  &__colorpreview {
    width: 24px;
    height: 24px;
    margin-left: 1rem;
    border: 1px solid;
  }
}

.k__bton--blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
