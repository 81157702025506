var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoices" },
    [
      _vm.hasLoaded
        ? _c("div", [
            _vm.stateFilters.length > 1
              ? _c(
                  "div",
                  { staticClass: "invoices__filters k__labels" },
                  _vm._l(_vm.stateFilters, function (filter) {
                    return _c(
                      "span",
                      {
                        key: filter.code,
                        staticClass:
                          "invoice__filter k__label --actif --status",
                        class: _vm.getStateClasses(filter.code, true),
                        on: {
                          click: function ($event) {
                            return _vm.setFilter(filter)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(filter.label) +
                            " (" +
                            _vm._s(filter.count) +
                            ")\n      "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.displayed.length > 0
              ? _c("div", { staticClass: "k__table__wrapper" }, [
                  _c("table", { staticClass: "k__table" }, [
                    _c("thead", { staticClass: "k__table__header" }, [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n[
                                "web__billings__header__reference"
                              ]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__date"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__amount"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.server.i18n["web__billings__header__state"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { staticClass: "k__table__body" },
                      _vm._l(_vm.displayed, function (invoice) {
                        return _c(
                          "tr",
                          {
                            key: invoice.reference,
                            staticClass: "k__table__line",
                          },
                          [
                            _c("td", { staticClass: "k__table__col" }, [
                              _vm._v(_vm._s(invoice.reference)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _vm._v(_vm._s(invoice.date)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _c("b", [_vm._v(_vm._s(invoice.amountTotal))]),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "k__label --status",
                                  class: _vm.getStateClasses(invoice.state),
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(invoice.stateLabel) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "k__table__col" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "k__bton --mini --secondary",
                                  attrs: { href: invoice.pdfUrl },
                                },
                                [
                                  _c("bdc-icon", {
                                    attrs: { icon: "download" },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.server.i18n[
                                          "web__billings__download"
                                        ]
                                      ) +
                                      "\n              "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _c(
                  "p",
                  { staticClass: "k__alert --info invoices__disclaimer" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.server.i18n["web__billings__no-invoices"]) +
                        "\n    "
                    ),
                  ]
                ),
          ])
        : _c("loading"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }