var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible || _vm.preview
    ? _c(
        "div",
        {
          staticClass: "bdc-message k__modal__wrapper",
          class: {
            "bdc-message--clickable": _vm.clickable,
            "bdc-message--media": _vm.isMedia,
          },
        },
        [
          _c("div", {
            staticClass: "k__modal__overlay",
            style: { background: _vm.message.color },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "k__modal__content",
              style: _vm.isMedia ? _vm.mediaSizeStyle : {},
            },
            [
              _c(
                "div",
                {
                  staticClass: "k__modal__close",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("✕")]
              ),
              _vm._v(" "),
              _vm.message.contentType === "html"
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.message.body) },
                    on: { click: _vm.cta },
                  })
                : _vm.message.contentType === "image"
                ? _c("div", {
                    staticClass: "bdc-message__image",
                    style: _vm.imageStyle,
                    on: { click: _vm.cta },
                  })
                : _vm.message.contentType === "video"
                ? _c(
                    "video",
                    {
                      staticClass: "bdc-message__video",
                      style: _vm.mediaSizeStyle,
                      attrs: {
                        autoplay: "",
                        playsinline: "",
                        loop: "",
                        muted: "",
                        controls: "",
                      },
                      domProps: { muted: true },
                    },
                    [
                      _c("source", {
                        attrs: { src: _vm.message.video, type: "video/mp4" },
                      }),
                      _vm._v(
                        "\n      Oups ! Votre navigateur ne peut pas afficher notre vidéo...\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.clickable
                ? _c("div", { staticClass: "bdc-message__actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "k__bton --secondary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.cta.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n        VOIR\n      ")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }