var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "k__loading" }, [
    _vm.isOk
      ? _c("div", { staticClass: "k__loading__content" }, [
          _c(
            "svg",
            {
              staticClass: "k__loading__svg",
              staticStyle: { transform: "rotate(0deg)" },
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "25 25 50 50",
              },
            },
            [
              _c("circle", {
                staticClass: "k__loading__svg__path",
                attrs: {
                  fill: "transparent",
                  cx: "50",
                  cy: "50",
                  r: "20",
                  "stroke-width": "5",
                  "stroke-dasharray": "125.664",
                  "stroke-dashoffset": "125.66370614359172px",
                },
              }),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }