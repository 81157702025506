var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "k__grid__item", attrs: { disabled: !_vm.item.isActive } },
    [
      _c(
        "a",
        {
          staticClass: "k__shortcut",
          class: { "--mini": _vm.mini, "--disabled": !_vm.item.isActive },
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.triggerItem()
            },
          },
        },
        [
          _c("bdc-icon", { attrs: { icon: _vm.item.id } }),
          _vm._v(" "),
          _c("span", { staticClass: "k__shortcut__label" }, [
            _vm._v(_vm._s(_vm.item.label)),
          ]),
          _vm._v(" "),
          _vm.item.desc
            ? _c("span", { staticClass: "k__shortcut__desc" }, [
                _vm._v(_vm._s(_vm.item.desc)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }