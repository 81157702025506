<template>
  <div v-if="visible || preview"
       class="bdc-message k__modal__wrapper"
       :class="{'bdc-message--clickable': clickable, 'bdc-message--media': isMedia}">
    <div class="k__modal__overlay"
         :style="{'background': message.color}"
         @click="close()"></div>
    <div class="k__modal__content"
         :style="isMedia ? mediaSizeStyle : {}">
      <div class="k__modal__close"
           @click="close()">&#x2715;</div>

      <div v-if="message.contentType === 'html'"
           @click="cta"
           v-html="message.body"></div>
      <div class="bdc-message__image"
           v-else-if="message.contentType === 'image'"
           @click="cta"
           :style="imageStyle"/>
      <video class="bdc-message__video"
             :style="mediaSizeStyle"
             v-else-if="message.contentType === 'video'"
             autoplay
             playsinline
             loop
             muted
             controls>
        <source :src="message.video"
                type="video/mp4">
        Oups ! Votre navigateur ne peut pas afficher notre vidéo...
      </video>

      <div v-if="clickable"
           class="bdc-message__actions">
        <button @click.prevent="cta"
                class="k__bton --secondary">
          VOIR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        message: {type: Object, default: null},
        preview: {type: Boolean, default: false}
    },
    data() {
        return {
            visible: true
        }
    },
    computed: {
        clickable() {
            return !!this.message.url;
        },
        isMedia() {
            return this.message.image || this.message.video;
        },
        imageStyle() {
            let style = {...this.mediaSizeStyle};
            style['background-image'] = 'url(' + this.message.image + ')';

            return style;
        },
        mediaSizeStyle() {
            return {
                'max-width': this.message.width + 'px',
                'max-height': this.message.height + 'px',
                'aspect-ratio': this.message.width + ' / ' + this.message.height
            };
        }
    },
    methods: {
        cta() {
          this._markClosed(true)
              .then(() => this.visible = false)
              .then(() => this.clickable ? window.open(this.message.url, '_blank') : null)
        },
        close() {
            this._markClosed(false)
                .then(() => this.visible = false);
        },
        _markClosed(permanent) {
            if (this.preview) {
                return Promise.resolve();
            }

            return fetch('/api/message/' + this.message.id + '/close?permanent=' + (permanent ? 1 : 0), {method: 'POST'});
        }
    }
};
</script>

<style lang="scss">
.bdc-message {
  .k__modal__close {
    border-radius: 3rem;
    border: 1px solid;
    background: white;
    padding: 0.5rem;
    position: absolute;
    width: 3rem;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    z-index: 1;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }

  .k__modal__content {
    margin: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
    //should be done through image, video or html
    background: transparent;
    box-shadow: none;
  }

  &--clickable {
    .k__modal__content {
      margin-top: -60px;
      cursor: pointer;
    }
  }

  &--media {
    .k__modal__content {
      padding: 0;
      background: transparent;
      box-shadow: none;
      width: 80%;
    }
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    width: 100%;
  }

  &__video {
    text-align: center;
    margin: auto;
    width: 100%;
  }

  &__actions {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
