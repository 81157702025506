import "../klixi/regicom/dist/js/script.min.js";
import "./app.scss";
import "../klixi/klixi_app/dist/js/chunk-vendors.js";
import "../klixi/klixi_app/dist/js/app.js";

import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faFacebookF, faFacebookMessenger, faLinkedinIn, faWhatsapp,} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import BdcPasswordChange from "./components/BdcPasswordChange.vue";
import BdcIcon from "./components/BdcIcon.vue";
import BdcItems from "./components/BdcItems.vue";
import BdcSubnav from "./components/BdcSubnav.vue";
import BdcPopin from "./components/BdcPopin.vue";
import BdcReporting from "./components/BdcReporting.vue";
import BdcSocials from "./components/BdcSocials.vue";
import BdcInvoices from "./components/BdcInvoices.vue";
import BdcContracts from "./components/BdcContracts.vue";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import BdcMessage from "./components/BdcMessage.vue";
import BdcMessageEdit from "./components/BdcMessageEdit.vue";

Vue.use(VueTelInput);

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("BdcIcon", BdcIcon);
Vue.component("BdcSelect", () => import("./components/BdcSelect.vue"));
Vue.component("BdcPhone", () => import("./components/BdcPhone.vue"));
Vue.component("BdcDatepicker", () => import("./components/BdcDatepicker.vue"));

library.add(faEnvelope, faPhoneAlt, faFacebookF, faFacebookMessenger, faWhatsapp, faLinkedinIn);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.server = Server;
Vue.prototype.eventHub = new Vue();

Vue.prototype.request = (config) => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "json";
        xhr.open(config.method || "GET", config.url);
        if (config.headers) {
            Object.keys(config.headers).forEach((key) => {
                xhr.setRequestHeader(key, config.headers[key]);
            });
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(xhr.statusText);
            }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(config.body);
    });
};

Vue.prototype.formatOdooDate = (date) => {
    if (!date) {
        return "";
    }

    const parts = date.split("-");
    const parsed = new Date(parts[0], parts[1] - 1, parts[2]);

    if (!parsed) {
        return "";
    }

    return (
        ("0" + parsed.getDate()).slice(-2) +
        "/" +
        ("0" + (parsed.getMonth() + 1)).slice(-2) +
        "/" +
        parsed.getFullYear()
    );
};

Vue.prototype.formatOdooAmount = (amount) => {
    return (amount ? amount.toLocaleString() : 0) + " €";
};

Vue.prototype.formatOdooState = (state) => {
    const MAP = {
        paid: Server.i18n["web__billings__state__paid"],
        open: Server.i18n["web__billings__state__open"],
        canceled: Server.i18n["web__billings__state__canceled"],
        "in-progress": Server.i18n["web__billings__state__in-progress"],
        closed: Server.i18n["web__billings__state__closed"],
        upcoming: Server.i18n["web__billings__state__upcoming"],
    };

    return MAP.hasOwnProperty(state) ? MAP[state] : state;
};

Vue.prototype.$wait = (secondes) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, secondes * 1000);
    });
};


new Vue({
    el: "#root",
    components: {
        BdcPasswordChange,
        BdcIcon,
        BdcItems,
        BdcSubnav,
        BdcPopin,
        BdcReporting,
        BdcSocials,
        BdcInvoices,
        BdcContracts,
        BdcMessageEdit,
        BdcMessage,
        BdcHours: () => import("./components/BdcHours.vue"),
        Loading: () => import("../klixi/klixi_app/src/components/Loading.vue"),
    },
    data() {
        return {
            message: null,
            recovery: true,
            hasAdblocker: false,
        };
    },
    created() {
        this.eventHub.$on("showAlert", (message) => (this.message = message));
        this.eventHub.$on("hideAlert", () => {
            this.message = null;
            this.recovery = false;
        });
    },
    mounted() {
        this.displayInvisibleAd().catch(() => this.hasAdblocker = true);
    },
    methods: {
        fakeGetPosts() {
            this.request({url: this.server.url["api_post"]})
                .then((response) => console.log(response))
                .catch((error) => console.error(error));
        },
        displayInvisibleAd() {
            return new Promise((resolve, reject) => {
                const element = document.createElement('div');
                element.classList.add('adBanner');
                element.style.cssText = 'height: 1px; width: 1px; background-color: transparent';
                document.body.appendChild(element);
                window.setTimeout(
                    () => {
                        document.querySelector('.adBanner').clientHeight === 0 ? reject() : resolve();
                        document.body.removeChild(element);
                    },
                    20
                );
            });
        },
    },
});
