import Vue from 'vue';
import dayjs from 'dayjs';

let duration = require('dayjs/plugin/duration');
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(duration);
// dayjs.extend(relativeTime);

function checkHtmlKey(entry, key) {
  if (!entry.html) return entry[key];
  if (!entry.html[key]) return entry[key];
  return entry.html[key];
}

Vue.filter('user', (user) => {
  if (user.last_name && user.first_name)
    return `${checkHtmlKey(user, 'first_name')} ${checkHtmlKey(
      user,
      'last_name'
    )}`;
  if (user.mail) return user.mail;
  return user.last_name || user.name;
});
Vue.filter('relativeHumanize', (date, language = 'fr') => {
  const diff = dayjs(date).diff(dayjs(), 'day');
  // const minuteDiff = dayjs(date).diff(dayjs(), 'second');
  // console.log('CHECK DIFF', minuteDiff);
  // if (minuteDiff > -60) return 'now';
  if (diff > -1) {
    return dayjs(date).format('HH:mm');
  }
  if (diff > -5)
    return dayjs
      .duration(dayjs(date).diff(dayjs()))
      .locale(language)
      .humanize(true);
  return dayjs(date).format('DD/MM/YYYY');
});
Vue.filter('humanize', (date, language = 'fr') => {
  return dayjs
    .duration(dayjs(date).diff(dayjs()))
    .locale(language)
    .humanize(true);
});
// Vue.filter("format", (val) => {
//   return dayjs(val).format("DD MMMM YYYY"); //dayjs(val).format(format);
// });
Vue.filter('format', (val, format = 'DD MMMM YYYY') => {
  return dayjs(val).format(format); //dayjs(val).format(format);
});

Vue.filter('formatDay', (val) => {
  return dayjs(val).format('DD'); //dayjs(val).format(format);
});

Vue.filter('trim', (string) => {
  return string.trim();
});

Vue.filter('noNetwork', (string) => {
  return string ? string.replace('Network', '').trim() : string;
});

Vue.filter('message', (s) => {
  if (!s) return;
  let result = s.split('Message : ');
  result = result[1] || result[0];
  return result.replace(/\\n/g, '').replace(/\n/g, '');
});
