<template>
  <div class="invoices">
    <div v-if="hasLoaded">
      <div class="invoices__filters k__labels" v-if="stateFilters.length > 1">
        <span
          v-for="filter of stateFilters"
          :key="filter.code"
          class="invoice__filter k__label --actif --status"
          :class="getStateClasses(filter.code, true)"
          @click="setFilter(filter)"
        >
          {{ filter.label }} ({{ filter.count }})
        </span>
      </div>
      <div v-if="displayed.length > 0" class="k__table__wrapper">
        <table class="k__table">
          <thead class="k__table__header">
            <tr>
              <th>{{ server.i18n['web__billings__header__reference'] }}</th>
              <th>{{ server.i18n['web__billings__header__date'] }}</th>
              <th>{{ server.i18n['web__billings__header__amount'] }}</th>
              <th>{{ server.i18n['web__billings__header__state'] }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="k__table__body">
            <tr
              class="k__table__line"
              v-for="invoice in displayed"
              :key="invoice.reference"
            >
              <td class="k__table__col">{{ invoice.reference }}</td>
              <td class="k__table__col">{{ invoice.date }}</td>
              <td class="k__table__col">
                <b>{{ invoice.amountTotal }}</b>
              </td>
              <td class="k__table__col">
                <span
                  class="k__label --status"
                  :class="getStateClasses(invoice.state)"
                >
                  {{ invoice.stateLabel }}
                </span>
              </td>
              <td class="k__table__col">
                <a :href="invoice.pdfUrl" class="k__bton --mini --secondary">
                  <bdc-icon icon="download" />
                  {{ server.i18n['web__billings__download'] }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="k__alert --info invoices__disclaimer" v-else>
        {{ server.i18n['web__billings__no-invoices'] }}
      </p>
    </div>
    <loading v-else />
  </div>
</template>

<script>
import Loading from '../../klixi/klixi_app/src/components/Loading.vue'

export default {
  components: { Loading },
  data() {
    return {
      hasLoaded: false,
      currentFilter: null,
      invoices: [],
    }
  },
  created() {
    this.request({ url: this.server.url['api_invoice'] })
      .then(
        (invoices) =>
          (this.invoices = invoices.map((invoice) =>
            this._makeInvoice(invoice),
          )),
      )
      .finally(() => (this.hasLoaded = true))
  },
  computed: {
    stateFilters() {
      if (this.invoices.length <= 0) {
        return []
      }

      let stateFilters = {}

      this.invoices.forEach((invoice) => {
        if (!stateFilters.hasOwnProperty(invoice.state)) {
          stateFilters[invoice.state] = {
            code: invoice.state,
            label: invoice.stateLabel,
            count: 1,
          }
        } else {
          stateFilters[invoice.state].count++
        }
      })

      return Object.values(stateFilters)
    },
    displayed() {
      return !this.currentFilter
        ? this.invoices
        : this.invoices.filter(
            (invoice) => invoice.state === this.currentFilter.code,
          )
    },
  },
  methods: {
    _makeInvoice(invoice) {
      invoice.amountTotal = this.formatOdooAmount(invoice.amountTotal)
      invoice.date = this.formatOdooDate(invoice.date)
      invoice.stateLabel = this.formatOdooState(invoice.state)
      invoice.pdfUrl = this.server.url['api_invoice_doc'].replace(
        'INVOICE_ID',
        invoice.ID,
      )

      return invoice
    },
    getStateClasses(stateCode, canBeActive) {
      let classes = ['paid', 'canceled', 'closed'].includes(stateCode)
        ? '--release'
        : '--warning'

      if (
        canBeActive &&
        this.currentFilter &&
        stateCode === this.currentFilter.code
      ) {
        classes += ' --active'
      }

      return classes
    },
    setFilter(filter) {
      if (this.currentFilter && this.currentFilter.code === filter.code) {
        this.currentFilter = null

        return
      }

      this.currentFilter = filter
    },
  },
}
</script>
