var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.orderedItemIds, function (item) {
      return _c("bdc-item", {
        key: _vm.randomId + "_" + item.id + "_" + item.desc,
        attrs: { mini: _vm.mini, item: item },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }